import Input from '../input/Input';
import ModalFooter from '../modal-components/modal-footer/ModalFooter';
import {useState} from 'react';
import Counter from '../counter/Counter';
import MainButton from '../button/MainButton';
import {ReactComponent as BoltGreen} from '../../assets/svg/bolt-green.svg';

const TransferConfirmData = ({
	setSmsCode,
	setApiKeyPassword,
	handleDetailsConfirm,
	smsCode,
	apiKeyPassword,
	invalidConfirmation
}) => {
	const [counter, setCounter] = useState(0);

	const codeMaxLength = 6;

	return (
		<div className='content transfer-assets'>
			<div className='title'>
				Transfer assets · Step 3
			</div>
			<p className='description'>
				Please confirm SMS code and provide password
			</p>
			<div className='with-counter'>
				<Input
					onChange={(e) => setSmsCode(e.target.value)}
					type='text'
					id='sms'
					className='provider-input input-margin'
					placeholder='SMS code'
				/>
				<Counter counter={ counter } setCounter={ setCounter }/>
			</div>
			<Input
				onChange={(e) => setApiKeyPassword(e.target.value)}
				type='password'
				id='apiPassword'
				className='provider-input'
				placeholder='API Key password'
			/>
			<MainButton
				className='confirm-button'
				onClick={handleDetailsConfirm}
				disabled={smsCode.length !== codeMaxLength || !apiKeyPassword || counter === 0}
				icon={<BoltGreen className='bolt-icon'/>}
				label='Confirm details'
			/>
			{invalidConfirmation && (
				<div className='error-text'>
					{invalidConfirmation}
				</div>
			)}
			<ModalFooter/>
		</div>
	);
};

export default TransferConfirmData;