import './createCryptoSaving.scss';
import CryptoSavingInput from './CryptoSavingInput';
import MainButton from '../../button/MainButton';
import { useContext } from 'react';
import { CryptoSavingsContext } from '../../../context/crypto-savings/CryptoSavingsContext';
import { frequencies } from '../../../utils/savings';
import {STEPS} from '../steps';
import {mockedBitcoinValue} from '../mockedBitcoinValue';
import { ReactComponent as CalendarIcon } from '../../../assets/svg/calendar.svg';
import { ReactComponent as BoltGreen } from '../../../assets/svg/bolt-green.svg';

export const mockedCurrencies = [
	{ currency: 'EUR' },
];

export const mockedCryptoTypes = [
	{ cryptoType: 'BTC' },
];

const CreateCryptoSaving = ({
	amount,
	setAmount,
	estimatedValue,
	cryptoType,
	setCryptoType,
	currency,
	setCurrency
}) => {

	const {
		setStep,
		frequency,
		frequencyDescription,
	} = useContext(CryptoSavingsContext);

	const handleFrequencyOpen = () => {
		setStep(STEPS.CRYPTO_FREQUENCY);
	};

	const handleConfirm = () => {
		setStep(STEPS.CRYPTO_SAVING_COMPLETE);
	};

	const frequencyText = frequency !== null ? `${frequencies[frequency]} ${frequencyDescription}` : 'Set frequency';

	return (
		<div className='crypto-savings'>
			<div className='create-saving-title'>
				Setup digital assets savings order
			</div>
			<div className='inputs-container'>
				<CryptoSavingInput
					value={amount}
					onOptionClick={(option) => setCurrency(option.currency)}
					onChange={(e) => setAmount(e.target.value)}
					options={mockedCurrencies}
					keySelector='currency'
					selectPlaceholder='USD'
					inputPlaceholder='-$100'
					selected={currency}
				/>
				<CryptoSavingInput
					onOptionClick={(option) => setCryptoType(option.cryptoType)}
					options={mockedCryptoTypes}
					keySelector='cryptoType'
					selectPlaceholder='BTC'
					inputPlaceholder={`+${estimatedValue ? estimatedValue.toFixed(8) : mockedBitcoinValue}`}
					selected={cryptoType}
					readOnly
				/>
				<div className='crypto-savings-input'>
					<span className='input-label'>Frequency</span>
					<button className='calendar-frequency-container' onClick={handleFrequencyOpen} disabled>
						<CalendarIcon className='calendar-icon' />
						<p className='frequency-text'>
							{frequencyText}
						</p>
					</button>
				</div>
			</div>
			<MainButton
				className='confirm-button'
				onClick={handleConfirm}
				disabled={!frequency || !amount}
				label='Review order'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
		</div>
	);
};

export default CreateCryptoSaving;
