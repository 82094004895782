import './cryptoSecurityWords.scss';
import {useContext, useEffect, useMemo, useState} from 'react';
import {CryptoSavingsContext} from '../../../context/crypto-savings/CryptoSavingsContext';
import Input from '../../input/Input';
import MainButton from '../../button/MainButton';
import getRandomWords from '../../../utils/getRandomWords';
import {ReactComponent as DownloadIcon} from '../../../assets/svg/download.svg';

const CryptoSecurityWords = ({ onClick }) => {
	const { generatedMnemonic } = useContext(CryptoSavingsContext);
	const [randomWords, setRandomWords] = useState([]);
	const [userInputs, setUserInputs] = useState({});

	useEffect(() => {
		const mnemonicArray = generatedMnemonic.split(' ').map((word, index) => ({
			word: word,
			index: index + 1,
		}));
		const generatedRandomWords = getRandomWords(mnemonicArray, 2);
		setRandomWords(generatedRandomWords.sort((a, b) => a.index - b.index));
	}, []);

	const isMatching = useMemo(() => {
		return randomWords.every((word) => userInputs[word.index] === word.word);
	}, [randomWords, userInputs]);

	const handleInputChange = (event, index) => {
		const newValue = event.target.value;
		setUserInputs((prevInputs) => ({ ...prevInputs, [index]: newValue }));
	};

	return (
		<div className='security-words'>
			<div className='security-text'>
				<div className='security-title'>
					<p>Confirm security words from your certificate</p>
				</div>
				<p className='security-description'>
						Fill in the words from your Security certificate in a corresponding position
				</p>
			</div>
			<form className='security-inputs'>
				{randomWords.map((word) => (
					<div className='input-element' key={word.index}>
						<p>{`Word position: ${word.index}`}</p>
						<Input
							placeholder='Security word'
							value={userInputs[word.index] || ''}
							onChange={(e) => handleInputChange(e, word.index)}
						/>
					</div>
				))}
			</form>
			<div className='button'>
				<MainButton
					className='download-button'
					onClick={onClick}
					disabled={!isMatching}
					label='CONFIRM OWNERSHIP'
					icon={<DownloadIcon className='download-icon'/>}
				/>
			</div>
		</div>
	);
};

export default CryptoSecurityWords;