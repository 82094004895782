import Input from '../input/Input';
import ModalFooter from '../modal-components/modal-footer/ModalFooter';
import Select from '../select/Select';
import MainButton from '../button/MainButton';
import {ReactComponent as BoltGreen} from '../../assets/svg/bolt-green.svg';

const TransferSetData = ({
	setCurrency,
	currency,
	setDestinationAddress,
	destinationAddress,
	setAmount,
	amount,
	handleNextStep,
	providers
}) => {

	return (
		<div className='content transfer-assets'>
			<div className='title'>
				Transfer assets · Step 1/3
			</div>
			<p className='description'>
				You are going to transfer your assets to the following address.
			</p>
			<Select
				options={providers}
				onOptionClick={(option) => setCurrency(option.account_name)}
				keySelector='account_name'
				placeholder='Choose asset'
				value={currency.toUpperCase()}
			/>
			<Input
				onChange={(e) => setDestinationAddress(e.target.value)}
				type='text'
				className='provider-input input-margin'
				placeholder='Account/Address'
				value={destinationAddress}
			/>
			<Input
				onChange={(e) => setAmount(e.target.value)}
				type='number'
				className='provider-input'
				placeholder='Amount'
				value={amount}
			/>
			<MainButton
				className='confirm-button'
				onClick={() => handleNextStep()}
				disabled={!currency || !amount || !destinationAddress}
				icon={<BoltGreen className='bolt-icon'/>}
				label='Continue'
			/>
			<ModalFooter/>
		</div>
	);
};

export default TransferSetData;