import AppRoutes from './routes/AppRoutes';
import { createAppKit } from '@reown/appkit/react';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { mainnet } from '@reown/appkit/networks';
import { useConfig } from './components/config/config';


const App = () => {

	// Global Web3Modal setup
	const {getConfig} = useConfig();
	const projectId = getConfig('walletConnectProjectId');
	const metadata = {
		name: 'Blockmate.io',
		description: 'Blockmate',
		url: window.location.origin,
		icons: ['https://link-dev-ovh.blockmate.io/blockmate-logo-for-walletconnect.png'],
	};

	createAppKit({
		adapters: [new EthersAdapter()],
		networks: [mainnet],
		metadata,
		projectId,
		defaultNetwork: mainnet, // For Coinbase SDK
		enableCoinbase: false, // If you remove this, it will work on PC, but the modal won't open on Android
	});
	
	return <AppRoutes/>;
};

export default App;