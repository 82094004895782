import './cryptoSavingsFrequency.scss';
import {ReactComponent as Checked} from '../../../assets/svg/check.svg';

const FrequencyRow = ({children, onClick, checked}) => {
	return (
		<div className='frequency-row' onClick={onClick}>
			{children}
			{checked && <Checked/>}
		</div>
	);
};

export default FrequencyRow;