import { ReactComponent as SecurityCertificate } from '../../../assets/svg/security-certificate.svg';
import './saving-certificate.scss';
import {useContext, useEffect, useState} from 'react';
import useEntropyGeneration from '../../../hooks/useEntropyGeneratorWithXor';
import Certificate from '../../Pdf/Certificate';
import {CryptoSavingsContext} from '../../../context/crypto-savings/CryptoSavingsContext';
import useApiInstances from '../../../services/api-call/axios';
import ErrorValidation from '../../errors/ErrorValidation';
import {entropyHexToMnemonic} from '../../../utils/withdraw/mnemonic';

const SavingCertificate = ({handleNextStep}) => {
	const {entropy, generateEntropy} = useEntropyGeneration();
	const {setGeneratedMnemonic} = useContext(CryptoSavingsContext);
	const [serverEntropy, setServerEntropy] = useState('');
	const [error, setError] = useState(null);
	const {apiLink} = useApiInstances();

	useEffect(() => {
		const getServerEntropy = async () => {
			try {
				const {data} = await apiLink.get('payment/entropy', {
					params : {
						chain: 'BTC'
					}
				});
				setServerEntropy(data.entropy);
			} catch (error) {
				setError(error.response);
			}
		};

		getServerEntropy();
	}, []);

	useEffect(() => {
		if (serverEntropy) {
			generateEntropy(serverEntropy);
		}
	}, [serverEntropy]);

	useEffect(() => {
		if (entropy) {
			const generatedMnemonic = entropyHexToMnemonic(entropy);
			setGeneratedMnemonic(generatedMnemonic);
		}
	}, [entropy]);

	if (error) {
		return (
			<ErrorValidation errorData={error}/>
		);
	}

	return (
		<div className='saving-certificate'>
			<div className='graphic'>
				<SecurityCertificate/>
			</div>
			<div className='certificate-text'>
				<div className='certificate-title'>
					<p>Your digital assets savings account was opened successfully!</p>
				</div>
				<p className='certificate-description'>
					Please save your Ownership certificate so only you have access to your crypto account.
				</p>
			</div>
			<div className='button'>
				{entropy && <Certificate
					entropyHex={entropy}
					handlePdfDownload={handleNextStep}
					isDisabled={error}
				/>}
			</div>
		</div>
	);
};

export default SavingCertificate;
