import Loading from './loading';
import Error from './Error';
import Success from './Success';
import {shortenString} from '../../utils/utils';
import MainButton from '../button/MainButton';
import { ReactComponent as BoltGreen } from '../../assets/svg/bolt-green.svg';

const ConnectionMessage = ({response, account, closeAction}) => {
	if (response === 'loading' || response === '') {
		return <Loading />;
	}
	if (response !== 'success') {
		return <div className='connection-message-container'>
			<div className='center-horizontal'>
				<Error />
			</div>
			<div className='message-heading'>
				There was an error connecting your account:
			</div>
			<div className='message-info'>
				{response}
			</div>
		</div>;
	}

	return <div className='connection-message-container'>
		<div className='center-horizontal'>
			<Success />
		</div>
		<div className='center-horizontal'>
			<div className='success-badge'>{shortenString(account, 20)}</div>
		</div>
		<div className='message-heading'>
			Your account was successfully connected!
		</div>
		<div className='message-info'>
			The connection was successful and this account is now available for use.
		</div>
		<div className='message-button-container'>
			<MainButton
				className='confirm-button'
				onClick={closeAction}
				icon={<BoltGreen className='bolt-icon'/>}
				label='Close window'
			/>
		</div>
	</div>;
};

export default ConnectionMessage;