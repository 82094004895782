import wordlist from 'web-bip39/wordlists/english';

export const suggestWord = (prefix) => {
	let left = -1;
	let right = wordlist.length - 1;
	while (right - left > 1) {
		const mid = Math.floor((left + right) / 2);
		if (prefix > wordlist[mid]) {
			left = mid;
		} else {
			right = mid;
		}
	}
	if (wordlist[right].startsWith(prefix)) {
		return wordlist[right];
	}
	return '';
};
