import styles from './payments.module.scss';
import { ReactComponent as BlockmateLogo } from '../../assets/svg/blockmate-logo-general.svg';
import { ReactComponent as BoltGreen } from '../../assets/svg/bolt-green.svg';
import MainButton from '../../components/button/MainButton';
import { useSearchParams } from 'react-router-dom';
import {handleOpen, LinkModal} from 'blockmate-react-link';
import useDeposit from '../../services/api-call/deposit';
import useDirectDeposit from '../../services/api-call/directDeposit';
import { useState, useMemo } from 'react';
import PersistentAlert, {ALERT_TYPES} from '../../components/persistent-alert/PersistentAlert';

const Payments = () => {
	const nonDeletableQueryParamKeys = [
		'jwt',
		'depositId',
		'depositType',
		'merchantDescription',
		'merchantIcon',
		'successUrl',
		'orderId',
	];

	const [searchParams] = useSearchParams();

	const [paymentInProgress, setPaymentInProgress] = useState(() => {
		const deletableParams = [];
		searchParams.forEach((_, key) => {
			if (!nonDeletableQueryParamKeys.includes(key)) {
				deletableParams.push(key);
			}
		});
		return deletableParams.length > 0;
	});

	const [errors, setErrors] = useState([]);

	const nonDeletableQueryParams = useMemo(() => {
		const params = {};
		for (const key of nonDeletableQueryParamKeys) {
			const value = searchParams.get(key);
			if (value) {
				params[key] = value;
			}
		}
		return params;
	}, [searchParams]);

	const linkUrl = window.location.origin;

	const { simpleOverview: exchangeDepositSimpleOverview } = useDeposit(nonDeletableQueryParams.jwt);
	const { simpleOverview: directDepositSimpleOverview } = useDirectDeposit(nonDeletableQueryParams.jwt);

	const handleProcessPayment = async () => {
		setPaymentInProgress(true);
		setErrors([]);
		const getDepositInfo = nonDeletableQueryParams.depositType === 'deposit'
			? exchangeDepositSimpleOverview
			: directDepositSimpleOverview;
		let depositInfo = {};
		try {
			depositInfo = await getDepositInfo(nonDeletableQueryParams.depositId);
		} catch(err) {
			setErrors(prevErrors => [...prevErrors, 'Problem getting deposit info. Please start a new process through the merchant.']);
			setPaymentInProgress(false);
			return;
		}

		if (depositInfo?.state_detail) {
			setErrors(prevErrors => [
				...prevErrors,
				`The state of this deposit is ${String(depositInfo.state_detail).toUpperCase()} and cannot be manipulated anymore.`]
			);
			return;
		}

		if (nonDeletableQueryParams.depositType === 'deposit') {
			handleOpen(
				'deposit',
				undefined,
				undefined,
				{
					providerName: depositInfo.name,
					depositId: nonDeletableQueryParams.depositId,
					fiatAmount: String(depositInfo.fiat_amount),
					fiatCurrency: depositInfo.fiat_currency,
					jwt: nonDeletableQueryParams.jwt,
					merchantDescription: nonDeletableQueryParams.merchantDescription,
					merchantIcon: nonDeletableQueryParams.merchantIcon,
				}
			);
		} else if (nonDeletableQueryParams.depositType === 'directDeposit') {
			handleOpen(
				'directDeposit',
				undefined,
				undefined,
				{
					depositId: nonDeletableQueryParams.depositId,
					jwt: nonDeletableQueryParams.jwt,
					merchantDescription: nonDeletableQueryParams.merchantDescription,
					merchantIcon: nonDeletableQueryParams.merchantIcon,
				}
			);
		}
	};

	const restartProcess = () => {
		const currentUrl = new URL(window.location.href);
		const newParams = new URLSearchParams();
		for (const [key, value] of Object.entries(nonDeletableQueryParams)) {
			newParams.set(key, value);
		}
		window.location.href = `${currentUrl.origin}${currentUrl.pathname}?${newParams.toString()}`;
	};

	const returnToMerchant = () => {
		window.location.href = nonDeletableQueryParams.successUrl;
	};

	return <div className={styles.container}>
		<div className={styles.allAlertsContainer}>
			{(errors ?? []).length > 0 && <div className={styles.errorsContainer}>
				{errors.map((error, index) => <div className={styles.error} key={`error-${index}`}>
					<PersistentAlert
						type={ALERT_TYPES.error}
						message={error}
						handleClose={() => {
							setErrors(prevErrors => {
								const newErrors = [...prevErrors];
								newErrors.splice(index, 1);
								return newErrors;
							});
						}}
					/>
				</div>)}
			</div>}
		</div>
		<div className={styles.contentContainer}>
			<LinkModal
				url={linkUrl}
				jwt={nonDeletableQueryParams.jwt}
				additionalUrlParams={{
					merchantDescription: nonDeletableQueryParams.merchantDescription,
					merchantIcon: nonDeletableQueryParams.merchantIcon,
				}}
				cleanupActions={{
					depositSuccess: returnToMerchant,
				}}
			/>

			<div className={styles.logoContainer}>
				<BlockmateLogo/>
			</div>
			<div className={styles.title}>Complete the cryptocurrency payment of your order</div>

			<div className={styles.explanation}>
				<span className={styles.alertText}>Do not close</span> this site until you are redirected back to the merchant.
			</div>

			<MainButton
				className='confirm-button'
				onClick={handleProcessPayment}
				icon={<BoltGreen className='bolt-icon'/>}
				disabled={paymentInProgress}
				label='Pay now'
			/>

			{paymentInProgress && <div className={styles.restartProcess} onClick={restartProcess}>
				&#x21bb; Restart process
			</div>}
		</div>
		<div></div> {/* Empty div so that space-between puts main div to the middle */}
	</div>;
};

export default Payments;
