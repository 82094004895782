import Loading from './loading';
import React from 'react';
import './style.scss';

const LoadingScreen = () => (
	<div className='modal-container'>
		<div className='modal-content'>
			<div className="content-wrapper">
				<Loading/>
			</div>
		</div>
	</div>
);

export default LoadingScreen;