import styles from './second-factor.module.scss';
import MainButton from '../../../button/MainButton';
import {useState} from 'react';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';

const SecondFactor = ({ provider, submitTx }) => {
	const [token2fa, setToken2fa] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = () => {
		setDisabled(true);
		submitTx(token2fa);
	};

	return <div className={styles.container}>
		<div className={styles.topContainer}>
			<div className={styles.title}>
				2FA required
			</div>
			<div className={styles.description}>
				Enter the 2FA code for {provider?.name} from your authenticator app.
				If you do not have an authenticator app, you should receive your code in
				an SMS or email shortly.
			</div>
			<input
				type='text'
				onChange={e => setToken2fa(e.target.value)}
				className={styles.secondFactorInput}
			/>
		</div>
		<div className={styles.bottomContainer}>
			<MainButton
				onClick={handleSubmit}
				className='confirm-button'
				icon={<BoltGreen className="bolt-icon" />}
				disabled={disabled}
				label='Submit'
			/>
		</div>
	</div>;
};

export default SecondFactor;
