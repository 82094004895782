import ModalHeader from '../modal-components/modal-header/ModalHeader';
import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import useApiInstances from '../../services/api-call/axios';
import WithdrawSetData from './WithdrawSetData';
import WithdrawCheckData from './WithdrawCheckData';
import './style.scss';
import WithdrawPrivateKeyDefault from './WithdrawPrivateKeyDefault';
import WithdrawAssetsIntro from './intro/WithdrawAssetsIntro';
import BlockmateLogoBlack from '../../assets/svg/blockmate-logo-black.svg';
import {ReactComponent as ExitIcon} from '../../assets/svg/exit-icon.svg';
import MnemonicInputModal from './mnemonic-modal/MnemonicInputModal';
import {entropyHexToMnemonic, mnemonicToSeedHex} from '../../utils/withdraw/mnemonic';
import WithdrawFinalize from './WithdrawFinalize';
import WithdrawPrivateKeyQR from './WithdrawPrivateKeyQR';
import {handleClose as linkHandleClose} from 'blockmate-react-link';

export const STEPS = {
	INTRO: 0,
	SET: 1,
	CHECK: 2,
	PRIVATE_KEY_TEXT: 3,
	PRIVATE_KEY_MNEMONIC: 4,
	PRIVATE_KEY_QR: 5,
	END: 6
};

const NEXT_STEP = {
	[STEPS.INTRO]: STEPS.SET,
	[STEPS.SET]: STEPS.CHECK,
	[STEPS.CHECK]: STEPS.PRIVATE_KEY_TEXT,
	[STEPS.PRIVATE_KEY_TEXT]: STEPS.END,
	[STEPS.PRIVATE_KEY_MNEMONIC]: STEPS.END,
	[STEPS.PRIVATE_KEY_QR]: STEPS.END,
};

const PREV_STEP = {
	[STEPS.INTRO]: STEPS.INTRO,
	[STEPS.SET]: STEPS.INTRO,
	[STEPS.CHECK]: STEPS.SET,
	[STEPS.PRIVATE_KEY_TEXT]: STEPS.CHECK,
	[STEPS.PRIVATE_KEY_MNEMONIC]: STEPS.PRIVATE_KEY_TEXT,
	[STEPS.PRIVATE_KEY_QR]: STEPS.PRIVATE_KEY_TEXT,
	[STEPS.END]: STEPS.PRIVATE_KEY_TEXT
};

const SUPPORTED_WITHDRAW_CURRENCIES = [
	{
		ticker: 'EUR',
	},
	{
		ticker: 'USD'
	}
];

const WithdrawAssetsModal = () => {
	const [invalidConfirmation, setInvalidConfirmation] = useState(null);
	const [cryptoAmount, setCryptoAmount] = useState('');
	const [fiatCurrency, setFiatCurrency] = useState(SUPPORTED_WITHDRAW_CURRENCIES[0].ticker);
	const [step, setStep] = useState(STEPS.INTRO);
	const [seed, setSeed] = useState(null);
	const [derivationPath, setDerivationPath] = useState(null);
	const [sourceAddress, setSourceAddress] = useState(null);
	const [destinationAddress, setDestinationAddress] = useState(null);
	const [redeemScript, setRedeemScript] = useState(null);

	const cryptoCurrency = 'BTC'; // Hardcoded for now, no more options available

	const [searchParams] = useSearchParams();
	const {apiLink} = useApiInstances();

	const providedAccount = searchParams.get('accountId');

	const handleClose = (e) => {
		linkHandleClose();
	};

	const handleBackArrow = () => {
		setStep(PREV_STEP[step]);
	};

	const handleNextStep = () => {
		setStep(NEXT_STEP[step]);
	};

	const handleConfirm = async () => {
		try {
			const response = await apiLink.post('payment/withdraw/initialize', {
				account_id: providedAccount,
				fiat_currency: fiatCurrency,
				crypto_amount: cryptoAmount
			});
			if (response.status === 200) {
				setInvalidConfirmation(null);
				window.location.href = response.data.redirect_uri;
			}
		} catch (error) {
			setInvalidConfirmation(error.message);
		}
	};

	const handleMnemonicSubmit = (mnemonic) => {
		const seedHex = mnemonicToSeedHex(mnemonic);
		setSeed(seedHex);
	};

	const handleEntropy = (entropy) => {
		const mnemonic = entropyHexToMnemonic(entropy);
		const seedHex = mnemonicToSeedHex(mnemonic);
		setSeed(seedHex);
	};

	const getAccountPaymentDetails = async (withdrawId) => {
		const response = await apiLink.get('payment', {
			params: {
				account_id: providedAccount
			}
		});
		if (response.status === 200) {
			const data = response.data;
			// TODO add withdrawal id verification here once available
			return {
				sourceAddress: data.wallet,
				derivationPath: data.user_derivation_path,
				redeemScript: data.redeem_script
			};
		}
	};

	// Check for ongoing withdrawal params
	useEffect(() => {
		const setTxSigningData = async () => {
			const withdrawId = searchParams.get('externalTransactionId');
			const amount = searchParams.get('baseCurrencyAmount');
			const depositAddress = searchParams.get('depositWalletAddress');
			if (withdrawId && depositAddress && amount) {
				const accountPaymentDetails = await getAccountPaymentDetails(withdrawId);
				setSourceAddress(accountPaymentDetails.sourceAddress);
				setDerivationPath(accountPaymentDetails.derivationPath);
				setRedeemScript(accountPaymentDetails.redeemScript);
				setDestinationAddress(depositAddress);
				setCryptoAmount(amount);

				setStep(STEPS.PRIVATE_KEY_TEXT);
			}
		};
		setTxSigningData();
	}, [searchParams]);

	// This modal is larger and therefore needs a separate container
	if (step === STEPS.PRIVATE_KEY_MNEMONIC) {
		return <MnemonicInputModal
			handleMnemonic={handleMnemonicSubmit}
			handleConfirm={handleNextStep}
			handleBackArrow={handleBackArrow}
			handleClose={handleClose}
		/>;
	}

	return (
		<div className='modal-container'>
			<div className='modal-content phone-modal'>
				{step === STEPS.INTRO
					? <ExitIcon className='exit-modal' onClick={handleClose}/>
					: <ModalHeader
						handleBackArrow={handleBackArrow}
						handleClose={handleClose}
						providerLogo={BlockmateLogoBlack}
						withArrow
					/>
				}
				{step === STEPS.INTRO && (
					<WithdrawAssetsIntro
						handleNextStep={handleNextStep}
					/>
				)}
				{step === STEPS.SET && (
					<WithdrawSetData
						setFiatCurrency={setFiatCurrency}
						fiatCurrency={fiatCurrency}
						cryptoCurrency={cryptoCurrency}
						cryptoAmount={cryptoAmount}
						setCryptoAmount={setCryptoAmount}
						handleNextStep={handleNextStep}
						fiatCurrencyOptions={SUPPORTED_WITHDRAW_CURRENCIES}
					/>
				)}
				{step === STEPS.CHECK && (
					<WithdrawCheckData
						fiatCurrency={fiatCurrency}
						cryptoAmount={cryptoAmount}
						cryptoCurrency={cryptoCurrency}
						handleNextStep={handleConfirm}
						invalidConfirmation={invalidConfirmation}
					/>
				)}
				{step === STEPS.PRIVATE_KEY_TEXT && (
					<WithdrawPrivateKeyDefault
						handleEntropy={handleEntropy}
						handleNextStep={handleNextStep}
						selectMnemonic={() => setStep(STEPS.PRIVATE_KEY_MNEMONIC)}
						selectQR={() => setStep(STEPS.PRIVATE_KEY_QR)}
					/>
				)}
				{step === STEPS.PRIVATE_KEY_QR && (
					<WithdrawPrivateKeyQR
						handleSeed={setSeed}
						handleNextStep={handleNextStep}
					/>
				)}
				{step === STEPS.END && (
					<WithdrawFinalize
						sourceAddress={sourceAddress}
						destinationAddress={destinationAddress}
						derivationPath={derivationPath}
						redeemScript={redeemScript}
						cryptoAmount={cryptoAmount}
						seedHex={seed}
					/>
				)}
			</div>
		</div>
	);
};
export default WithdrawAssetsModal;