import Select from '../../select/Select';
import Input from '../../input/Input';
import './createCryptoSaving.scss';

const CryptoSavingInput = ({options, keySelector, selectPlaceholder, inputPlaceholder, value, type, id, onChange, name, onOptionClick, readOnly, selected, className='', disabled}) => {

	return (
		<div className={`crypto-savings-input ${className}`}>
			<Select
				options={options}
				keySelector={keySelector}
				placeholder={selectPlaceholder}
				onOptionClick={onOptionClick}
				value={selected}
				disabled={disabled}
			/>
			<Input
				placeholder={inputPlaceholder}
				value={value}
				type={type}
				id={id}
				onChange={onChange}
				name={name}
				readOnly={readOnly}/>
		</div>
	);
};

export default CryptoSavingInput;
