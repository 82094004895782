import styles from './errors.module.scss';

const Errors = ({ errors }) => {
	return <div className={styles.container}>
		<ul>
			{errors.map((error, index) => <li className={styles.error} key={`${index}-${error}`}>
				{error}
			</li>)}
		</ul>
	</div>;
};

export default Errors;
