import './style.scss';
import appRoutes from '../../../routes/routes';

const ModalFooter = () => {
	const {external} = appRoutes;

	return (
		<div className='modal-footer'>
			{/* eslint-disable-next-line react/no-unescaped-entities */}
			<span>By selecting "Continue” you agree to</span>
			<a href={external.termsAndCondition} target={'_blank'} rel="noreferrer">Terms and Conditions</a>
		</div>
	);
};

export default ModalFooter;