import styles from './persistent-alert.module.scss';
import {clsx} from 'clsx';
import {ReactComponent as CrossIcon} from '../../assets/svg/cross.svg';


export const ALERT_TYPES = {
	success: 'success',
	error: 'error',
	info: 'info',
};

const PersistentAlert = ({type, message, handleClose}) => {

	const classByAlertType = {
		[ALERT_TYPES.success]: styles.success,
		[ALERT_TYPES.error]: styles.error,
		[ALERT_TYPES.info]: styles.info,
	};

	if (!Object.keys(ALERT_TYPES).includes(type)) {
		return null;
	}

	return <div className={clsx(styles.container, classByAlertType[type])}>
		<div className={styles.description}>
			{message}
		</div>
		<div className={styles.closeButton} onClick={() => handleClose()}>
			<CrossIcon/>
		</div>
	</div>;
};

export default PersistentAlert;