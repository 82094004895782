import IntroDescription from './IntroDescription';
import MainButton from '../../button/MainButton';
import {ReactComponent as BlockmateLogo} from '../../../assets/svg/blockmate-logo.svg';
import {ReactComponent as Layers} from '../../../assets/svg/layers.svg';
import {ReactComponent as InfoIcon} from '../../../assets/svg/info-icon.svg';
import {ReactComponent as Resend} from '../../../assets/svg/resend.svg';
import {ReactComponent as ExitIcon} from '../../../assets/svg/exit-icon.svg';
import {ReactComponent as BoltGreen} from '../../../assets/svg/bolt-green.svg';

const CryptoSavingsIntro = ({onClick, handleClose}) => {
	return (
		<>
			<div className='modal-intro-header'>
				<button onClick={handleClose} className='exit'>
					<ExitIcon/>
				</button>
				<div className='modal-intro-logo'>
					<BlockmateLogo/>
				</div>
				<div className='modal-intro-title'>
					<p>Invest regularly with crypto savings</p>
				</div>
			</div>
			<div className='modal-intro-descriptions-container savings-intro'>
				<IntroDescription
					title='How it works'
					description='Choose an asset and how frequently you&apos;d like to save.'
				>
					<InfoIcon/>
				</IntroDescription>
				<IntroDescription
					title='A simple and proven strategy'
					description='Investing frequently can perform better than timing the market.'
				>
					<Resend/>
				</IntroDescription>
				<IntroDescription
					title='Invest for your future'
					description='Sit back and relax while your savings grow over time.'
				>
					<Layers/>
				</IntroDescription>
			</div>
			<MainButton
				className='confirm-button'
				onClick={onClick}
				label='Open account'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
		</>
	);
};

export default CryptoSavingsIntro;