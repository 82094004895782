import CategoryField from './CategoryField';

const SearchCategory = ({title, items, selectedIndex, handleClick}) => {

	return <>
		<p className='list-title'>{title}</p>
		<ul className='provider-list'>
			{items?.sort((a, b) => a.url.localeCompare(b.url)).map((provider, idx) => {
				const isFocused = idx === selectedIndex;
				return (
					<CategoryField key={provider.url} provider={provider} isFocused={isFocused} handleClick={handleClick}/>
				);
			})}
		</ul>
	</>;
};

export default SearchCategory;