import Input from '../input/Input';
import MainButton from '../button/MainButton';
import { ReactComponent as BoltGreen } from '../../assets/svg/bolt-green.svg';

const EnableTransferWallet = ({apiKey, setApiKey, setApiSecret, apiSecret, handleNextStep, providerIp}) => {
	return (
		<div className='content confirm-transfer'>
			<div className='title'>
				Transactions API key
			</div>
			<p className='description'>
				Please provide API key with
				<br/>
				transactions permissions
			</p>
			<div className='input-container'>
				<Input
					onChange={(e) => setApiKey(e.target.value)}
					type='text'
					id='filter'
					className='provider-input'
					placeholder='API Key/Wallet'
					value={apiKey}
				/>
				<Input
					onChange={(e) => setApiSecret(e.target.value)}
					type='text'
					id='apiSecret'
					className='provider-input'
					placeholder='API secret'
					value={apiSecret}
				/>
			</div>
			<MainButton
				className='confirm-button'
				onClick={() => handleNextStep()}
				disabled={!apiKey || !apiSecret}
				label='continue'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
			<div className='info-box'>
				Use Blockmate&apos;s IP address if requested by a provider:
				<div className='ip-box'>
					{providerIp?.map(ip => (
						<span key={ip}>{ip}</span>
					))}
				</div>
			</div>
		</div>
	);
};

export default EnableTransferWallet;