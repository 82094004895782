import {useState} from 'react';
import Input from '../input/Input';
import MainButton from '../button/MainButton';
import useApiInstances from '../../services/api-call/axios';
import importPublicKey from '../../utils/encryptions/importPublicKey';
import encryptData from '../../utils/encryptions/encryptData';
import {handleClose} from 'blockmate-react-link';
import {ReactComponent as BoltGreen} from '../../assets/svg/bolt-green.svg';

const EnableTransferConfirm = ({apiKey, apiSecret, accountId, provider}) => {
	const [error, setError] = useState(null);
	const [password, setPassword] = useState(null);
	const [passwordConfirm, setPasswordConfirm] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const {apiLink} = useApiInstances();

	const publicKeyPEM = provider?.payment.encryption_key;

	const handleTransferEnable = async () => {
		if (password !== passwordConfirm) return;
		setError(null);
		const publicKey = await importPublicKey(publicKeyPEM);
		const requestData = {
			'api_key': await encryptData(publicKey, apiKey),
			'api_secret': await encryptData(publicKey, apiSecret),
			'password': await encryptData(publicKey, password),
			'account_id': accountId,
		};
		try {
			setIsLoading(true);
			const response = await apiLink.post('payment/account', requestData);
			if (response.status === 200) {
				handleClose(response.data.callback);
			}
		}
		catch (error) {
			setError(error.response.data.detail);
		}
		setIsLoading(false);
	};

	const isDisabled = !password || !passwordConfirm || password !== passwordConfirm || isLoading || error;

	return (
		<div className='content confirm-transfer'>
			<div className='title'>
				Set transactions password
			</div>
			<p className='description'>
				For security reasons, you will need this password for every transaction with
				<br/>
				this API key
			</p>
			<Input
				onChange={(e) => setPassword(e.target.value)}
				type='password'
				id='password'
				className='provider-input input-margin'
				placeholder='Password'
			/>
			<Input
				onChange={(e) => setPasswordConfirm(e.target.value)}
				type='password'
				id='passwordConfirm'
				className='provider-input'
				placeholder='Confirm password'
			/>
			<MainButton
				className='confirm-button'
				onClick={handleTransferEnable}
				disabled={isDisabled}
				label='Confirm'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
			{error && (
				<div className='error-text'>
					{error}
				</div>
			)}
		</div>
	);
};

export default EnableTransferConfirm;