import '../search-provider/style.scss';
import {useContext} from 'react';
import {ProviderContext} from '../../../context/provider/ProviderContext';
import ModalHeader from '../modal-header/ModalHeader';
import WalletConnectLogoBlack from '../../../assets/svg/wallet-connect-logo-black.svg';
import SearchCategory from '../common/SearchCategory';
import {useKeyboardNavigation} from '../../../hooks/useKeyboardNavigation';
import {useKeyPress} from '../../../hooks/useKeyPress';

const WalletConnectChainSelector = ({onClick, providersData, handleBackArrow, handleClose}) => {
	const {setProvider} = useContext(ProviderContext);

	const handleSelectProvider = (index) => {
		handleClick(providersData[index]);
	};

	const handleClick = (provider) => {
		setProvider(provider);
		onClick();
	};

	useKeyPress({
		Escape: handleClose,
		Backspace: handleBackArrow
	});

	const { selectedIndex } = useKeyboardNavigation(handleSelectProvider, providersData);

	return (
		<>
			<ModalHeader
				handleBackArrow={handleBackArrow}
				withArrow
				handleClose={handleClose}
				providerLogo={WalletConnectLogoBlack}
			/>
			<div className='select-provider-container'>
				<div className='providers-list-container'>
					<div className='select-provider'>
						<p className='select-provider-text'>Select your chain</p>
					</div>
					{providersData.length > 0 &&
						<SearchCategory
							title=''
							items={providersData}
							selectedIndex={selectedIndex}
							handleClick={handleClick}
						/>
					}
				</div>
			</div>
		</>
	);
};

export default WalletConnectChainSelector;