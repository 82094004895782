import {useEffect, useState} from 'react';
import {prepareAndSignTransaction} from '../../utils/withdraw/transaction';
import CryptoVerificationSuccess from '../crypto-savings/CryptoVerificationSuccess';
import Error from '../status/Error';
import {seedHexToChildPrivateKey} from '../../utils/withdraw/mnemonic';

const WithdrawFinalize = ({sourceAddress, destinationAddress, derivationPath, redeemScript, cryptoAmount, seedHex}) => {
	const [signedTx, setSignedTx] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (!seedHex) {
			setError('Missing private key');
		}
	}, []);
	
	useEffect(() => {
		if (!seedHex) {
			return;
		}

		const signTx = async () => {
			try {
				const derivedPrivateKey = seedHexToChildPrivateKey(seedHex, derivationPath);
				const partiallySignedTx = await prepareAndSignTransaction({
					sourceAddress,
					destinationAddress,
					redeemScript,
					amount: cryptoAmount,
					privateKeyHex: derivedPrivateKey
				});
				setSignedTx(partiallySignedTx);
				// TODO send partiallySIgnedTx to the correct endpoint here (get it from some parameter / backend)
				console.log(`partiallySignedTx: ${JSON.stringify(partiallySignedTx)}`);
			} catch (error) {
				setError(error.message);
			}
		};
		signTx();
	}, [seedHex]);

	if (signedTx && !error) {
		return <CryptoVerificationSuccess />;
	}
	
	return <div className='connection-message-container'>
		<div className='center-horizontal'>
			<Error />
		</div>
		<div className='message-heading'>
			Failed to sign the transaction
		</div>
		<div className='message-info'>
			{error}
		</div>
	</div>;
};

export default WithdrawFinalize;