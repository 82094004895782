import {useEffect, useRef, useState} from 'react';
import {suggestWord} from '../../../utils/bip39/suggest';
import {useKeyPress} from '../../../hooks/useKeyPress';

const MnemonicSingleWordInput = ({wordIndex, onChange}) => {
	const [prefix, setPrefix] = useState('');
	const [suggestion, setSuggestion] = useState('');
	const [isFocused, setIsFocused] = useState(false);

	const handleChange = (value) => {
		setPrefix(value);
		setSuggestion(suggestWord(value));
		onChange(value);
	};

	const acceptSuggestion = () => {
		if (isFocused && prefix.length) {
			handleChange(suggestion);
		}
	};

	useKeyPress({
		Tab: acceptSuggestion
	});

	const inputRef = useRef(null);

	// Only display the suggestion when the input is focused
	useEffect(() => {
		const handleFocus = () => {
			setIsFocused(document.activeElement === inputRef.current);
		};
		const handleBlur = () => {
			setIsFocused(false);
		};
		const componentRef = inputRef.current;

		if (componentRef) {
			componentRef.addEventListener('focus', handleFocus);
			componentRef.addEventListener('blur', handleBlur);
		}
		return () => {
			if (componentRef) {
				componentRef.removeEventListener('focus', handleFocus);
				componentRef.removeEventListener('blur', handleBlur);
			}
		};
	}, []);

	return <div className='single-word-input-container'>
		<div className='index-container'>{wordIndex}.</div>
		<div className='word-input'>
			<input
				type='text'
				value={isFocused && prefix.length ? suggestion : ''}
				readOnly
				disabled
				className='input-suggestion'
			/>
			<input
				type='text'
				value={prefix}
				onChange={e => handleChange(e.target.value)}
				ref={inputRef}
				className='small-input'
			/>
		</div>
	</div>;
};

export default MnemonicSingleWordInput;