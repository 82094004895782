export const shortenString = (str, maxLength) => {
	if (str.length <= maxLength) {
		return str;
	}
	const prefixLen = Math.ceil((maxLength - 3) / 2);
	const suffixLen = maxLength - prefixLen - 3;
	return str.substring(0, prefixLen) + '...' + str.substring(str.length - suffixLen);
};

export const chunkArray = (array, chunkSize) => {
	return array.reduce((acc, elem, index) => {
		const chunkIndex = Math.floor(index / chunkSize);
		if (!acc[chunkIndex]) {
			acc[chunkIndex] = [];
		}
		acc[chunkIndex].push(elem);
		return acc;
	}, []);
};

export const formatPrice = (price, currency = 'usd', fractionDigits = 0, withLeadingPlus = false) => {
	let formattedPrice =  (price ?? 0).toLocaleString('en-US', {
		style: 'currency',
		currency: currency ?? 'usd',
		maximumFractionDigits: fractionDigits
	});
	if (withLeadingPlus && price > 0) {
		formattedPrice = `+${formattedPrice}`;
	}
	return formattedPrice;
};

export const processNumberFromStrInput = (str) => {
	let inputStr = str;
	while (inputStr.startsWith('0')) {
		inputStr = inputStr.slice(1);
	}
	if (inputStr.length === 0 || inputStr.startsWith('.')) {
		inputStr = '0' + inputStr;
	}
	let addedZero = false;
	if (inputStr.endsWith('.')) {
		addedZero = true;
		inputStr += '0';
	}
	const number = Number(inputStr);
	if (isNaN(number)) {
		return undefined;
	}
	if (addedZero) {
		inputStr = inputStr.slice(0, -1);
	}
	return inputStr;
};

export const valueExists = (value) => value !== undefined && value !== null && value !== '';
