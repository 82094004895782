import './style.scss';
import clsx from 'clsx';

const Input = ({placeholder, value, type, id, onChange, className, name, maxLength, readOnly, innerRef}) => {
	const isSensitive = ['secret', 'passphrase'].some(sensitiveStr => String(name).includes(sensitiveStr));
	return (
		<input
			className={clsx(
				'main-input',
				className,
			)}
			ref={innerRef}
			placeholder={placeholder}
			value={value}
			type={type}
			id={id}
			onChange={onChange}
			name={name}
			maxLength={maxLength}
			readOnly={readOnly}
			autoComplete={isSensitive ? 'off' : 'on'}
		/>
	);
};

export default Input;
