import './verify-phone.scss';
import Input from '../input/Input';
import ModalFooter from '../modal-components/modal-footer/ModalFooter';
import MainButton from '../button/MainButton';
import clsx from 'clsx';
import {validatePhoneForE164} from '../../utils/phoneValidation';
import {ReactComponent as BlockmateInfo} from '../../assets/svg/blockmate-info.svg';
import {ReactComponent as BoltGreen} from '../../assets/svg/bolt-green.svg';

const VerifySetPhone = ({invalidData, setInvalidData, setPhone, phone, initializePhone}) => {

	const handleChange = (e) => {
		setInvalidData(false);
		setPhone(e.target.value);
		validatePhoneForE164(e.target.value);
	};

	const handlePhoneNumber = async () => {
		if (!validatePhoneForE164(phone)) {
			setInvalidData(true);
		} else {
			setInvalidData(false);
			await initializePhone();
		}
	};

	return (
		<div className="content">
			<div className="logo-info">
				<BlockmateInfo className="blockmate-info-icon"/>
			</div>
			<div className="title">
				Set one-time verification phone number
			</div>
			<p className="description">
				Before enabling secure asset transfers, please set your verification phone number to enable two-factor
				authentication
			</p>
			<div>
				<div>
					<Input
						onChange={handleChange}
						type="tel"
						id="phone"
						className={clsx(
							'provider-input',
							invalidData && 'input-error',
						)}
						placeholder="Phone number"
					/>
					<div className="error-text">
						{invalidData && 'Wrong phone format'}
					</div>
				</div>
				<MainButton
					className="confirm-button"
					onClick={handlePhoneNumber}
					disabled={!phone}
					label='Continue'
					icon={<BoltGreen className='bolt-icon'/>}
				/>
			</div>
			<ModalFooter/>
		</div>
	);
};

export default VerifySetPhone;