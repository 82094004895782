import {BrowserRouter, Routes, Route} from 'react-router-dom';
import PageNotFound from '../pages/page-not-found/PageNotFound';
import appRoutes from './routes';
import Modal from '../components/modal/Modal';
import ModalAmlDemo from '../components/modal/ModalAmlDemo';
import VerifyPhoneModal from '../components/verify-phone/VerifyPhoneModal';
import ChangePhoneModal from '../components/change-phone/ChangePhoneModal';
import EnableTransferModal from '../components/enable-transfer/EnableTransferModal';
import WithdrawAssetsModal from '../components/withdraw-assets/WithdrawAssetsModal';
import CryptoSavingsWrapper from '../components/crypto-savings/CryptoSavingsWrapper';
import TransferAssetsModal from '../components/transfer-assets/TransferAssetsModal';
import {useConfig} from '../components/config/config';
import DepositFromExchange from '../components/deposits/exchange/DepositFromExchange';
import DepositFromWalletConnect from '../components/deposits/wallet-connect/DepositFromWalletConnect';
import Payments from '../pages/payments/Payments';

const AppRoutes = () => {
	const {home, deposit} = appRoutes;
	const { getConfig } = useConfig();

	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path={home.modal} element={getConfig('isAmlDemo') ? <ModalAmlDemo/> : <Modal/> }/>
					<Route path={home.verifyPhone} element={<VerifyPhoneModal/>}/>
					<Route path={home.changePhone} element={<ChangePhoneModal/>}/>
					<Route path={home.enableTransfer} element={<EnableTransferModal/>}/>
					<Route path={home.transferAssets} element={<TransferAssetsModal/>}/>
					<Route path={home.cryptoSavings} element={<CryptoSavingsWrapper/>}/>
					<Route path={home.withdrawAssets} element={<WithdrawAssetsModal/>}/>
					<Route path={home.depositFromExchange} element={<DepositFromExchange/>}/>
					<Route path={home.depositFromWalletConnect} element={<DepositFromWalletConnect/>}/>
					<Route path={deposit.paymentsPage} element={<Payments/>}/>
					<Route path='*' element={<PageNotFound/>}/>
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default AppRoutes;
