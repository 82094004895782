import createConfig from 'react-runtime-config';

export const { useConfig, useAdminConfig } = createConfig({
	namespace: 'MY_APP_CONFIG',
	schema: {
		walletConnectProjectId: {
			type: 'string',
			description: 'Project ID for WalletConnect',
			default: ''
		},
		walletConnectRelayUrl: {
			type: 'string',
			description: 'Relay URL for WalletConnect',
			default: 'wss://relay.walletconnect.com'
		},
		isAmlDemo: {
			type: 'boolean',
			description: 'Is AML demo',
			default: false
		},
		apiUrl: {
			type: 'string',
			description: 'Blockmate API URL',
			default: 'https://api-dev-ovh.blockmate.io'
		},
	},
});