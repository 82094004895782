import './style.scss';
import Loading from '../../status/loading';
import Input from '../../input/Input';
import ModalHeader from '../modal-header/ModalHeader';
import ConnectionMessage from '../../status/ConnectionMessage';
import MainButton from '../../button/MainButton';
import {ProviderContext} from '../../../context/provider/ProviderContext';
import {useEffect, useState, useContext, createRef, useRef} from 'react';
import {useKeyPress} from '../../../hooks/useKeyPress';
import useApiInstances from '../../../services/api-call/axios';
import {parseFieldsResponse} from '../../../utils/ParseFieldsResponse';
import {ReactComponent as BoltGreen} from '../../../assets/svg/bolt-green.svg';
import {handleRedirect} from 'blockmate-react-link';
import {useSearchParams} from 'react-router-dom';

const Form = ({token, oauthConnectedParam, oauthConnectedAccount, handleBackArrowSuccess, handleBackArrowNoSuccess, handleClose}) => {
	const [isLoading, setIsLoading] = useState(true);
	const {provider, setProvider} = useContext(ProviderContext);
	const [form, setForm] = useState({});
	const [invalidData, setInvalidData] = useState(null);
	const [response, setResponse] = useState();
	const [disabled, setDisabled] = useState(false);
	const [inputRefs, setInputRefs] = useState({});
	const submitButtonRef = useRef();
	const {apiLink} = useApiInstances();
	const [searchParams] = useSearchParams();

	const handleBackArrowByResponse = () => {
		if (response === 'success') {
			handleBackArrowSuccess();
		} else {
			handleBackArrowNoSuccess();
		}
	};

	const handleConnect = ({ form, provider, token, onSuccess, onError }) => {
		const controller = new AbortController();

		const sendInputsData = async () => {
			try {
				const resp = await apiLink.post('/account',
					{
						...form,
						'account_url': provider.url
					},
					{
						signal: controller.signal,
						params: { 'jwt': token },
						headers: { 'content-type': 'application/json' }
					}
				);

				if (resp.status === 200) {
					onSuccess();
				}
			} catch (error) {
				onError(error);
			}
		};

		sendInputsData().catch(console.error);

		return () => {
			controller.abort();
		};
	};

	useEffect(() => {
		if (oauthConnectedAccount) {
			setResponse('success');
		}
	}, []);

	useEffect(() => {
		if (provider?.fields) {
			const updatedInputRefs = {};
			provider.fields.forEach(field => {
				updatedInputRefs[field.key] = createRef();
			});
			setInputRefs(updatedInputRefs);
		}
	}, [provider]);

	const handleChange = (e) => {
		const value = e.target.value;
		setForm({...form, [e.target.name]: value});
	};

	const handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}
		setResponse(null);
		if (Object.keys(form).length < provider.fields.length || Object.values(form).some(el => el.length === 0)) {
			setInvalidData(true);
		} else {
			setDisabled(true);
			setInvalidData(false);
			const onResolve = (responseText) => {
				setIsLoading(false);
				setResponse(responseText);
				setDisabled(false);
			};
			handleConnect({
				form,
				provider,
				token,
				onSuccess: () => onResolve('success'),
				onError: (error) => onResolve(error.response.data.detail)
			});
		}
	};

	const someFieldIsFocused = () => Object.values(inputRefs).some(ref => ref.current === document.activeElement);

	useKeyPress({
		Backspace: () => {
			if (!someFieldIsFocused()) {
				handleBackArrowByResponse();
			}
		},
		Escape: () => {
			if (!someFieldIsFocused()) {
				handleClose(response);
			} else {
				Object.values(inputRefs).forEach(ref => {
					if (ref.current) {
						ref.current.blur();
					}
				});
			}
		},
		Enter: handleSubmit
	});

	useEffect(() => {
		const controller = new AbortController();
		const getProviderData = async () => {
			try {
				const isOAuth = provider.url.startsWith('oauth/');
				const params = {'jwt': token};

				if (isOAuth) {
					const parentUrl = new URL(Buffer.from(searchParams.get('parentUrlEncoded'), 'base64').toString());
					parentUrl.searchParams.set(oauthConnectedParam, `${provider.name}`);
					params['redirect_url'] = parentUrl.href;
				}

				const res = await apiLink.get(provider.url.replace('/', '_'), {
					signal: controller.signal,
					params: params,
				});

				if (res.status === 200) {
					if (isOAuth) {
						handleRedirect(res.data.redirect_url);
					} else {
						setProvider({...provider, fields: parseFieldsResponse(res.data.fields)});
						setIsLoading(false);
					}
				}
			} catch (error) {
				if (error.message !== 'canceled') {
					setIsLoading(false);
				}
			}
		};

		getProviderData();

		return () => {
			controller.abort();
		};
	}, [provider?.url, token]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<ModalHeader
				handleBackArrow={handleBackArrowByResponse}
				withArrow
				handleClose={() => {
					handleClose(response);
				}}
				providerLogo={provider?.icon}
			>
				<span className='capitalize'>{provider?.name ?? ''}</span>
			</ModalHeader>
			{response === 'success'
				? <ConnectionMessage
					response={response}
					account={oauthConnectedAccount ?? form[provider.fields[0].key]}
					closeAction={() => handleClose(response)}
				/>
				: <div className='provider-container'>
					<p className='provider-name'>Connect <span className='capitalize'>{provider.name}</span></p>
					<p className='provider-description'>By entering your
						<span className='provider-name-nested capitalize'> {provider.name}
						</span> adress we securely retrieve your read-only information
					</p>
					<form onSubmit={handleSubmit}>
						{(provider?.fields ?? []).map((field) =>
							(
								<div key={field.key}>
									<Input
										innerRef={inputRefs[field.key]}
										placeholder={field.label}
										onChange={handleChange}
										name={field.key}
										value={form[field.key] || ''}
										className='connect-input'
									/>
									{field.invalid && 'required'}
								</div>
							))
						}
						<div className='input-alert'>{invalidData && 'All form are required'}</div>
						<div className='input-alert'>{response}</div>
						<MainButton
							innerRef={submitButtonRef}
							type='submit'
							className='confirm-button'
							onClick={handleSubmit}
							disabled={disabled}
							label={disabled ? 'CONNECTING...' : 'CONNECT'}
							icon={<BoltGreen className='bolt-icon'/>}
						/>
					</form>
				</div>
			}
		</>
	);
};

export default Form;