import './style.scss';
import ModalHeader from '../modal-components/modal-header/ModalHeader';
import useApiInstances from '../../services/api-call/axios';
import TransferSetData from './TransferSetData';
import TransferCheckData from './TransferCheckData';
import TransferConfirmData from './TransferConfirmData';
import importPublicKey from '../../utils/encryptions/importPublicKey';
import encryptData from '../../utils/encryptions/encryptData';
import ErrorValidation from '../errors/ErrorValidation';
import useStepNavigation from '../../hooks/useStepNavigation';
import LoadingScreen from '../status/LoadingScreen';
import { useState, useEffect } from 'react';
import { useSearchParams} from 'react-router-dom';
import {handleClose} from 'blockmate-react-link';

export const STEPS = {
	SET: 1,
	CHECK: 2,
	CONFIRM: 3,
};

const TransferAssetsModal = () => {
	const [invalidConfirmation, setInvalidConfirmation] = useState(null);
	const [form, setForm] = useState({
		destinationAddress: '',
		amount: '',
		currency: '',
		smsCode: '',
		apiKeyPassword: '',
	});
	const [mfaId, setMfaId] = useState(null);
	const [nonce, setNonce] = useState(null);
	const [providers, setProviders] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [account, setAccount] = useState(null);
	const [provider, setProvider] = useState(null);
	const {step, handleBackArrow, handleNextStep} = useStepNavigation(STEPS.SET);
	const [validationCorrect, setValidationCorrect] = useState(false);
	const [searchParams] = useSearchParams();
	const {apiLink, apiBlockmate} = useApiInstances();

	const providedAccount = searchParams.get('accountId');
	const publicKeyPEM = provider?.payment.encryption_key;

	useEffect(()=> {
		const getProvidersForSelect = async () => {
			setIsLoading(true);
			try {
				const response = await apiBlockmate.get('v1/aggregate/account_provider_hints');
				setProviders(response.data.filter(provider => provider.account_type === 'crypto_wallet'));
			} catch (error) {
				setValidationCorrect(false);
			}
			setIsLoading(false);
		};

		getProvidersForSelect();
	}, []);

	const handleConfirm = async () => {
		setIsLoading(true);
		try {
			const response = await apiLink.post('payment/initialize', {
				'wallet_id': account.wallet_id,
				'destination_address': form.destinationAddress,
				'amount': form.amount,
				'currency': form.currency,
				'account_id': account.id,
			});
			if (response.status === 200) {
				setInvalidConfirmation(null);
				setMfaId(response.data.mfa_id);
				setNonce(response.data.nonce);
				handleNextStep();
			}
		} catch (error) {
			setInvalidConfirmation(error.message);
		}
		setIsLoading(false);
	};

	const handleDetailsConfirm = async () => {
		const publicKey = await importPublicKey(publicKeyPEM);
		const encryptedPassword = await encryptData(publicKey, form.apiKeyPassword);
		try {
			const response = await apiLink.post('payment/authorize',
				{
					'wallet_id': account.wallet_id,
					'destination_address': form.destinationAddress,
					'amount': form.amount,
					'currency': form.currency,
					'nonce': nonce,
					'mfa_id': mfaId,
					'otp': form.smsCode,
					'password': encryptedPassword,
					'account_id': account.id,
				});
			if (response.status === 200) {
				handleClose(response.data.callback);
			}
		} catch (error) {
			setInvalidConfirmation(error.message);
		}
	};

	useEffect(  () => {
		const getAccounts = async () => {
			try {
				const response = await apiLink.get('accounts');
				if (response.status === 200) {
					setAccount(response.data.find(account => account.id === providedAccount));
				}
			} catch (error) {
				setIsLoading(false);
			}
		};

		getAccounts();
	}, []);

	useEffect(  () => {
		setIsLoading(true);
		const getProviderDetails = async () => {
			try {
				const response = await apiLink.get(`exchange_${account.name}`);
				if (response.status === 200) {
					setProvider(response.data);
					setIsLoading(false);
					setValidationCorrect(true);
				}
			} catch (error) {
				setIsLoading(false);
			}
		};

		if (account !== null) {
			getProviderDetails();
		}
	}, [account]);

	let stepComponent;
	switch (step) {
	case STEPS.SET:
		stepComponent = (
			<TransferSetData
				setCurrency={(value) => setForm({ ...form, currency: value })}
				currency={form.currency}
				setDestinationAddress={(value) => setForm({ ...form, destinationAddress: value })}
				destinationAddress={form.destinationAddress}
				setAmount={(value) => setForm({ ...form, amount: value })}
				amount={form.amount}
				handleNextStep={handleNextStep}
				setValidationCurrect={setValidationCorrect}
				providers={providers}
			/>
		);
		break;
	case STEPS.CHECK:
		stepComponent = (
			<TransferCheckData
				currency={form.currency}
				destinationAddress={form.destinationAddress}
				amount={form.amount}
				handleConfirm={handleConfirm}
				invalidConfirmation={invalidConfirmation}
			/>
		);
		break;
	case STEPS.CONFIRM:
		stepComponent = (
			<TransferConfirmData
				setSmsCode={(value) => setForm({ ...form, smsCode: value })}
				setApiKeyPassword={(value) => setForm({ ...form, apiKeyPassword: value })}
				handleDetailsConfirm={handleDetailsConfirm}
				smsCode={form.smsCode}
				apiKeyPassword={form.apiKeyPassword}
				invalidConfirmation={invalidConfirmation}
			/>
		);
		break;
	default:
		stepComponent = null;
	}

	if (isLoading) return <LoadingScreen/>;

	if (!validationCorrect) return (
		<div className='modal-container'>
			<div className='modal-content'>
				<ErrorValidation onClick={() => handleClose()} withExitIcon/>
			</div>
		</div>
	);

	return (
		<div className='modal-container'>
			<div className='modal-content phone-modal'>
				<ModalHeader
					handleBackArrow={handleBackArrow}
					handleClose={() => handleClose()}
					providerLogo={provider?.icon}
					withArrow={step !== STEPS.SET}
				>
					{provider?.description}
				</ModalHeader>
				{stepComponent}
			</div>
		</div>
	);
};

export default TransferAssetsModal;
