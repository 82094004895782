import QRCode from 'qrcode.react';

const QRGenerator = ({ value, id, size }) => {
	return (
		<QRCode
			id={id}
			value={value}
			bgColor="#FFF"
			fgColor="#000"
			level='H'
			size={size}
		/>
	);
};

export default QRGenerator;