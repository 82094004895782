import styles from './linked-logos.module.scss';
import {ReactComponent as LineWithCircle} from '../../../../assets/svg/line-with-circle.svg';

const LinkedLogos = ({ leftLogo, rightLogo }) => {
	return <div className={styles.container}>
		<div className={styles.logoContainer}>
			{leftLogo}
		</div>
		<div className={styles.lineWithCircleContainer}>
			<LineWithCircle />
		</div>
		<div className={styles.logoContainer}>
			{rightLogo}
		</div>
	</div>;
};

export default LinkedLogos;
