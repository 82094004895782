import {useEffect, useRef} from 'react';

const CategoryField = ({provider, isFocused, handleClick}) => {

	const selfRef = useRef();

	useEffect(() => {
		if (isFocused) {
			selfRef.current.scrollIntoView({
				block: 'center'
			});
		}
	}, [isFocused]);

	return <li
		ref={selfRef}
		key={provider.url}
		onClick={() => handleClick(provider)}
		className={`provider-list-item ${isFocused ? 'provider-list-item-focused' : ''}`}>
		<img
			src={provider.icon}
			alt={provider.url}
			className='provider-list-icons'
		/>
		<span>{provider.description}</span>
	</li>;
};

export default CategoryField;