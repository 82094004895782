import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useConfig } from '../../components/config/config';

const useApiInstances = () => {
	const { getConfig } = useConfig();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('jwt');

	const url = {
		link: process.env.REACT_APP_URL_LINK,
		api: getConfig('apiUrl')
	};

	const apiLink = axios.create({
		baseURL: '/v1/link/link',
		params: {'jwt': token}
	});

	const apiBlockmate = axios.create({
		baseURL: url.api
	});

	return {
		apiBlockmate,
		apiLink,
	};
};

export default useApiInstances;