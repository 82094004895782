import {useEffect, useRef, useState} from 'react';

export const useKeyboardNavigation = (handleSelect, providers) => {

	const [selectedIndex, setSelectedIndex] = useState(undefined);
	const selectedIndexRef = useRef();

	useEffect(() => {
		selectedIndexRef.current = selectedIndex;
	}, [selectedIndex]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Enter') {
				handleSelect(selectedIndexRef.current);
			} else if (event.key === 'ArrowUp') {
				setSelectedIndex((prev) => Math.max(0, prev - 1));
			} else if (event.key === 'ArrowDown') {
				setSelectedIndex((prev) => {
					if (prev === undefined) {
						return 0;
					}
					return Math.min(providers.length - 1, prev + 1);
				});
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleSelect, providers]);

	return {selectedIndex};
};
