import './cryptoSavings.scss';
import {useContext, useState} from 'react';
import ModalFooter from '../modal-components/modal-footer/ModalFooter';
import ModalHeader from '../modal-components/modal-header/ModalHeader';
import CryptoSavingsIntro from './crypto-savings-intro/CryptoSavingsIntro';
import CreateCryptoSaving from './create-crypto-saving/CreateCryptoSaving';
import CryptoSavingFrequency from './crypto-savings-frequency/CryptoSavingFrequency';
import SavingCertificate from './saving-certificate/SavingCertificate';
import CryptoSecurityWords from './crypto-security-words/CryptoSecurityWords';
import CryptoVerificationSuccess from './CryptoVerificationSuccess';
import CryptoSavingComplete from './crypto-saving-complete/CryptoSavingComplete';
import SavingSuccess from './saving-success/SavingsSuccess';
import {CryptoSavingsContext} from '../../context/crypto-savings/CryptoSavingsContext';
import {STEPS} from './steps';
import {mockedBitcoinValue} from './mockedBitcoinValue';
import {handleClose} from 'blockmate-react-link';
import {ReactComponent as BlockmateLogo} from '../../assets/svg/blockmate-logo-black.svg';

const CryptoSavings = () => {
	const {step, setStep} = useContext(CryptoSavingsContext);
	const [amount, setAmount] = useState(null);
	const [cryptoType, setCryptoType] = useState('BTC');
	const [currency, setCurrency] = useState('USD');
	const estimatedValue = amount * mockedBitcoinValue;

	//TODO estimate from BE when service is ready

	const handleBackArrow = () => {
		if (step !== STEPS.CRYPTO_SAVING_COMPLETE) {
			setStep(step - 1);
		}
		else {
			setStep(STEPS.NEW_CRYPTO_SAVING);
		}
	};

	const handleNextStep = () => {
		setStep(step +1);
	};

	const isHeaderWithArrow = [STEPS.CRYPTO_FREQUENCY, STEPS.CRYPTO_SAVING_COMPLETE].includes(step);

	return (
		<div className='modal-container crypto-savings-container'>
			<div className='modal-content phone-modal'>
				{step !== STEPS.CRYPTO_SAVING_INTRO &&
						<ModalHeader
							handleBackArrow={handleBackArrow}
							handleClose={() => handleClose()}
							withArrow={isHeaderWithArrow}
						>
							<BlockmateLogo/>
						</ModalHeader>
				}
				{step === STEPS.CRYPTO_SAVING_INTRO && (
					<CryptoSavingsIntro onClick={() => handleNextStep()} handleClose={() => handleClose()}/>
				)}
				{step === STEPS.CERTIFICATE && (
					<SavingCertificate handleNextStep={() => handleNextStep()}/>
				)}
				{step === STEPS.SECURITY_WORDS && (
					<CryptoSecurityWords onClick={() => handleNextStep()}/>
				)}
				{step === STEPS.SAVING_SUCCESS && (
					<SavingSuccess onClick={() => handleNextStep()}/>
				)}
				{step === STEPS.NEW_CRYPTO_SAVING && (
					<CreateCryptoSaving
						amount={amount}
						setAmount={setAmount}
						estimatedValue={estimatedValue}
						currency={currency}
						setCurrency={setCurrency}
						cryptoType={cryptoType}
						setCryptoType={setCryptoType}
					/>
				)}
				{step === STEPS.CRYPTO_FREQUENCY && (
					<CryptoSavingFrequency handleSchedule={() => handleNextStep()}/>
				)}
				{step === STEPS.CRYPTO_SAVING_COMPLETE && (
					<CryptoSavingComplete
						handleOrder={() => handleNextStep()}
						amount={amount}
						estimatedValue={estimatedValue}
						currency={currency}
						cryptoType={cryptoType}
					/>
				)}
				{step === STEPS.VERIFICATION_SUCCESS && (
					<CryptoVerificationSuccess handleClose={() => handleClose()}/>
				)}
				{step === STEPS.CRYPTO_SAVING_INTRO && (
					<ModalFooter/>
				)}
			</div>
		</div>
	);
};

export default CryptoSavings;