import CryptoSavingInput from '../create-crypto-saving/CryptoSavingInput';
import MainButton from '../../button/MainButton';
import useApiInstances from '../../../services/api-call/axios';
import {useContext, useEffect, useState} from 'react';
import {CryptoSavingsContext} from '../../../context/crypto-savings/CryptoSavingsContext';
import { frequencies } from '../../../utils/savings';
import {ReactComponent as BoltGreen} from '../../../assets/svg/bolt-green.svg';
import {ReactComponent as ResendIcon} from '../../../assets/svg/resend.svg';
import {entropyHexToRootExtendedPublicKey} from '../../../utils/withdraw/mnemonic';

const CryptoSavingComplete = ({
	amount,
	cryptoType,
	estimatedValue,
	currency
}) => {
	const {frequency, frequencyDescription, entropy, startDate} = useContext(CryptoSavingsContext);
	const {apiLink} = useApiInstances();
	const unixTimestamp = new Date().getTime() / 1000;
	const [accountId, setAccountId] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const connect = async () => {
			const paymentStart = startDate || unixTimestamp + 60;
			const rootExtendedPublicKey = entropyHexToRootExtendedPublicKey(entropy);
			try {
				const {data} = await apiLink.post('payment/connect', {
					'chain': cryptoType,
					'user_xpub_key': rootExtendedPublicKey,
					'description': 'pisp',
					'payment_frequency': 0,
					'payment_start': paymentStart
				});
				setAccountId(data.id);
			} catch (error) {
				setError('Something went wrong');
			}
		};

		connect();
	}, []);

	const deposit = async () => {
		try {
			const {data} = await apiLink.post('payment/deposit', {
				'account_id': accountId,
				'fiat_currency': currency,
				'fiat_amount': amount
			});
			if (data.redirect_uri) {
				window.location.href = data.redirect_uri;
			}
		} catch (error) {
			setError('Something went wrong');
		}
	};

	return (
		<div className='crypto-savings'>
			<div className='create-saving-title'>
				Review your order and create new crypto saving
			</div>
			<div className='inputs-container'>
				<CryptoSavingInput
					value={`-$ ${amount}`}
					selectPlaceholder={currency}
					readOnly
					className='read-only'
					disabled
				/>
				<CryptoSavingInput
					value={`+ ${estimatedValue.toFixed(8)}`}
					selectPlaceholder={cryptoType}
					readOnly
					className='read-only'
					disabled
				/>
				<div className='crypto-savings-input read-only'>
					<span>Frequency</span>
					<div className='calendar-frequency-container'>
						<ResendIcon className='calendar-icon'/>
						{frequencies[frequency]} {frequencyDescription}
					</div>
				</div>
				{error && <div className='error-text'>{error}</div>}
			</div>
			<MainButton
				className='confirm-button'
				onClick={deposit}
				label='Complete order'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
		</div>
	);
};

export default CryptoSavingComplete;
