import {createContext, useState} from 'react';

export const CryptoSavingsContext = createContext();

const CryptoSavingsContextProvider = ({children}) => {
	const [step, setStep] = useState(1);
	const [startDate, setStartDate] = useState(null);
	const [frequency, setFrequency] = useState(3);
	const [frequencyDescription, setFrequencyDescription] = useState('');
	const [entropy, setEntropy] = useState(null);
	const [generatedMnemonic, setGeneratedMnemonic] = useState('');
	const [userXPub, setUserXPub] = useState('');

	const data = {
		step,
		setStep,
		startDate,
		setStartDate,
		frequency,
		setFrequency,
		frequencyDescription,
		setFrequencyDescription,
		entropy,
		setEntropy,
		generatedMnemonic,
		setGeneratedMnemonic,
		userXPub,
		setUserXPub
	};

	return (
		<CryptoSavingsContext.Provider value={data}>{children}</CryptoSavingsContext.Provider>
	);
};

export default CryptoSavingsContextProvider;