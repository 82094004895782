import Input from '../input/Input';
import ModalFooter from '../modal-components/modal-footer/ModalFooter';
import Select from '../select/Select';
import MainButton from '../button/MainButton';
import {ReactComponent as BoltGreen} from '../../assets/svg/bolt-green.svg';
import {useKeyPress} from '../../hooks/useKeyPress';

const WithdrawSetData = ({
	fiatCurrency,
	setFiatCurrency,
	cryptoAmount,
	setCryptoAmount,
	cryptoCurrency,
	handleNextStep,
	fiatCurrencyOptions
}) => {
	useKeyPress({
		Enter: handleNextStep
	});

	return (
		<div className='content transfer-assets'>
			<div className='title'>
				Transfer assets · Step 1/3
			</div>
			<p className='description'>
				You are going to transfer your assets to the following address.
			</p>
			<div className='amount-input'>
				<Input
					onChange={(e) => setCryptoAmount(e.target.value)}
					type='number'
					className='provider-input'
					placeholder={`Amount in ${cryptoCurrency}`}
					value={cryptoAmount}
				/>
			</div>
			<Select
				options={fiatCurrencyOptions}
				onOptionClick={option => setFiatCurrency(option.ticker)}
				placeholder='Choose fiat currency'
				keySelector='ticker'
				value={fiatCurrency.toUpperCase()}
			/>
			<MainButton
				className='confirm-button btn-margin-top'
				onClick={handleNextStep}
				label='Continue'
				icon={<BoltGreen className='bolt-icon'/>}
				disabled={!fiatCurrency || !cryptoAmount}
			/>
			<ModalFooter/>
		</div>
	);
};

export default WithdrawSetData;