import Input from '../input/Input';
import OrLine from '../modal-components/common/OrLine';
import { ReactComponent as ConnectIcon } from '../../assets/svg/connect-icon.svg';
import { ReactComponent as QrCodeIcon } from '../../assets/svg/qr-code-icon.svg';
import { ReactComponent as BoltGreen } from '../../assets/svg/bolt-green.svg';
import MainButton from '../button/MainButton';
import {useState} from 'react';

const WithdrawPrivateKeyDefault = ({ selectMnemonic, selectQR, handleEntropy, handleNextStep }) => {
	const [entropy, setEntropy] = useState('');

	const handleSubmit = () => {
		handleEntropy(entropy);
		handleNextStep();
	};

	return (
		<div className='withdraw-pk-default-container'>
			<div className='withdraw-pk-default-main-container'>
				<div className='withdraw-modal-title'>Withdrawal confirmation</div>
				<div className='withdraw-modal-body'>
					<div className='withdraw-modal-info'>
						{'Copy Private key from your Ownership\'s certificate or scan the QR code'}
					</div>
					<Input
						onChange={(e) => setEntropy(e.target.value)}
						type='text'
						className='provider-input input-margin'
						placeholder='Fill in the Private key'
						value={entropy}
					/>
					<MainButton
						className='confirm-button'
						onClick={handleSubmit}
						label='Confirm private key'
						icon={<BoltGreen className='bolt-icon'/>}
					/>
					<div className='qr-scan-link-container'>
						<div className='qr-scan-icon'>
							<QrCodeIcon />
						</div>
						<button className='qr-scan-link' onClick={selectQR}>
							{'Scan certificate\'s QR code'}
						</button>
					</div>
				</div>
			</div>
			<OrLine />
			<div className='mnemonic-confirmation-container'>
				<div className='mnemonic-confirmation-title-container'>
					<div className='mnemonic-confirmation-icon'>
						<ConnectIcon />
					</div>
					<div className='mnemonic-confirmation-title'>
						Mnemonic Confirmation
					</div>
				</div>
				<MainButton
					className='secondary-button'
					onClick={selectMnemonic}
					label='Fill in 24 security words'
				/>
			</div>
		</div>
	);
};

export default WithdrawPrivateKeyDefault;