import { useState } from 'react';

const useStepNavigation = (initialStep = 0) => {
	const [step, setStep] = useState(initialStep);

	const handleBackArrow = () => {
		setStep(prevStep => prevStep - 1);
	};

	const handleNextStep = () => {
		setStep(prevStep => prevStep + 1);
	};

	return {
		step,
		handleBackArrow,
		handleNextStep
	};
};

export default useStepNavigation;