import styles from './error-modal-screen.module.scss';
import Error from '../../../status/Error';

const ErrorModalScreen = ({ message }) => {
	return <div className={styles.errorContainer}>
		<Error />
		<div className={styles.title}>
			Error
		</div>
		<div className={styles.description}>
			{message}
		</div>
	</div>;
};

export default ErrorModalScreen;
