import styles from './link-message.module.scss';
import Success from '../../../status/Success';
import Error from '../../../status/Error';
import MainButton from '../../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';

const LinkMessage = ({ isSuccess, message, handleNextStep, canProceed = true }) => {
	return <div className={styles.container}>
		<div className={styles.contentContainer}>
			{isSuccess ? <Success/> : <Error />}
			<div className={styles.title}>
				{isSuccess ? 'Success' : 'Error'}
			</div>
			<div className={styles.message}>
				{message}
			</div>
			{/*{isSuccess && <div className={styles.linkedAccountInfoContainer}>*/}
			{/*	Linked account name: {linkedAccountName}*/}
			{/*</div>}*/}
		</div>

		{isSuccess && <div>
			<MainButton
				className='confirm-button'
				onClick={handleNextStep}
				label='Continue to transaction'
				icon={<BoltGreen className='bolt-icon' />}
				disabled={!canProceed}
			/>
		</div>}
	</div>;
};

export default LinkMessage;
