import './counter.scss';
import useInterval from '../../hooks/useInterval';

const Counter = ({ counter, setCounter }) => {

	useInterval(() => {
		if (counter > 0) {
			setCounter(prevCounter => prevCounter - 1);
		}
	}, 1000);

	return (
		<span className='counter'>
			{counter}S TO VERIFY
		</span>
	);
};

export default Counter;