import styles from './provider-logo.module.scss';

const ProviderLogo = ({ provider }) => {
	return <div className={styles.container}>
		<div className={styles.icon}>
			<img src={provider?.icon} alt={provider?.description} />
		</div>
		<div className={styles.name}>
			{provider.description}
		</div>
	</div>;
};

export default ProviderLogo;
