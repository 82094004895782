import { mnemonicToSeedSync, entropyToMnemonic } from 'bip39';
import { networks, bip32 } from 'bitcoinjs-lib';

export const getNetworkFromEnv = () => {
	return process.env.REACT_APP_BTC_NETWORK === 'MAINNET' ? networks.bitcoin : networks.testnet;
};

export const seedHexToChildPrivateKey = (seedHex, derivationPath) => {
	const extendedRootPrivateKey = seedHexToExtendedRootPrivateKey(seedHex);
	const childExtendedPrivateKey = deriveExtendedPrivateKey(extendedRootPrivateKey, derivationPath);
	return childExtendedPrivateKeyToPrivateKey(childExtendedPrivateKey);
};

export const mnemonicToSeedHex = (mnemonicStr) => {
	return mnemonicToSeedSync(mnemonicStr).toString('hex');
};

export const entropyHexToMnemonic = (seedHex) => {
	return entropyToMnemonic(Buffer.from(seedHex, 'hex'));
};

export const entropyHexToRootExtendedPublicKey = (entropyHex) => {
	const mnemonic = entropyHexToMnemonic(entropyHex);
	const seedHex = mnemonicToSeedHex(mnemonic);
	const rootNode = bip32.fromSeed(Buffer.from(seedHex, 'hex'), getNetworkFromEnv());
	return rootNode.neutered().toBase58();
};

export const seedHexToExtendedRootPrivateKey = (seedHex) => {
	const network = getNetworkFromEnv();
	const seedBuffer = Buffer.from(seedHex, 'hex');
	const rootNode = bip32.fromSeed(seedBuffer, network);
	return rootNode.toBase58();
};

export const deriveExtendedPrivateKey = (extendedRootPrivateKey, derivationPath) => {
	const network = getNetworkFromEnv();
	const rootNode = bip32.fromBase58(extendedRootPrivateKey, network);
	const childNode = rootNode.derivePath(derivationPath);
	return childNode.toBase58();
};

export const childExtendedPrivateKeyToPrivateKey = (childExtendedPrivateKey) => {
	const network = getNetworkFromEnv();
	const childNode = bip32.fromBase58(childExtendedPrivateKey, network);
	const privateKeyBuf = childNode.privateKey;
	return privateKeyBuf.toString('hex');
};