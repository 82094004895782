import './style.scss';
import MainButton from '../../button/MainButton';
import ModalHeader from '../modal-header/ModalHeader';
import {useKeyPress} from '../../../hooks/useKeyPress';
import BlockmateLogoBlack from '../../../assets/svg/blockmate-logo-black.svg';
import {ReactComponent as QRCodeIcon} from '../../../assets/svg/qr-code-icon-green.svg';
import {ReactComponent as WalletConnectIcon} from '../../../assets/svg/wallet-connect.svg';
import {ReactComponent as MenuIcon} from '../../../assets/svg/connect-icon.svg';
import OrLine from '../common/OrLine';

const WalletConnectOption = ({onSelect}) => {
	return <div className='connect-option'>
		<div className='connect-option-header'>
			<WalletConnectIcon className='icon-small wallet-connect-logo'/><strong>Wallet connect</strong>
		</div>
		<div className='paragraph-explain'>
			Choose compatible wallet and connect your account instantly
		</div>
		<MainButton
			className='confirm-button'
			onClick={onSelect}
			label='Connect wallet'
			icon={<QRCodeIcon className='qr-code-icon' />}
		/>
	</div>;
};

const ManualOption = ({onSelect}) => {

	const AdvancedFeatureBadge = () => {
		return <div className='advanced-feature-badge'>
			ADVANCED FEATURE
		</div>;
	};

	return <div className='connect-option'>
		<AdvancedFeatureBadge />
		<div className='connect-option-info'>
			<div className='connect-option-header'>
				<MenuIcon className='icon-small' /><strong>Manual connection</strong>
			</div>
			<div className='paragraph-explain'>
				Connect your account manually
			</div>
		</div>
		<MainButton
			className='secondary-button'
			onClick={onSelect}
			label='Connect manually'
		/>
	</div>;
};

export const ConnectionTypeSelector = ({onSelectWalletConnect, onSelectManual, handleClose, handleBackArrow}) => {

	useKeyPress({
		Escape: handleClose,
		Backspace: handleBackArrow
	});

	return <div>
		<ModalHeader withArrow handleClose={handleClose} handleBackArrow={handleBackArrow} providerLogo={BlockmateLogoBlack}/>
		<div className='connection-type-selector-container'>
			<WalletConnectOption onSelect={onSelectWalletConnect} />
			<OrLine />
			<ManualOption onSelect={onSelectManual} />
		</div>
	</div>;
};