import './style.scss';
import clsx from 'clsx';

const MainButton = ({ icon, label, onClick, className, type, disabled, innerRef, is3D = true }) => (
	<button
		ref={innerRef}
		type={type}
		onClick={onClick}
		className={clsx(
			'main-button',
			is3D && 'button-3d',
			className
		)}
		disabled={disabled}
	>
		{icon}
		{label}
	</button>
);

export default MainButton;
