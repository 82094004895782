import ModalHeader from '../../modal-components/modal-header/ModalHeader';
import BlockmateLogoBlack from '../../../assets/svg/blockmate-logo-black.svg';
import {useMemo, useState} from 'react';
import MainButton from '../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../assets/svg/bolt-green.svg';
import {useKeyPress} from '../../../hooks/useKeyPress';
import MnemonicPhraseInput from './MnemonicPhraseInput';

const MnemonicInputModal = ({ handleBackArrow, handleClose, handleMnemonic, handleConfirm }) => {
	const MNEMONIC_LENGTH = 24;
	const COLUMNS = 3;

	const [mnemonic, setMnemonic] = useState(Array(MNEMONIC_LENGTH).fill(''));
	const allFilled = useMemo(() => mnemonic.every(word => word.length), [mnemonic]);

	const handleSubmit = () => {
		if (allFilled) {
			handleMnemonic(mnemonic.join(' '));
			handleConfirm();
		}
	};

	useKeyPress({
		Enter: handleSubmit
	});

	return <div className='modal-container'>
		<div className='mnemonic-input-modal-content'>
			<ModalHeader
				handleBackArrow={handleBackArrow}
				handleClose={handleClose}
				withArrow
				providerLogo={BlockmateLogoBlack}
			/>
			<div className='mnemonic-input-modal-body'>
				<div className='mnemonic-input-modal-title-section'>
					<div className='mnemonic-modal-title'>
						Mnemonic confirmation
					</div>
					<div className='mnemonic-modal-information'>
						{'Please fill in 24 security words forom Ownership\'s certificate'}
					</div>
				</div>
				<MnemonicPhraseInput mnemonic={mnemonic} setMnemonic={setMnemonic} columns={COLUMNS} />
				<div className='button-container'>
					<MainButton
						className='confirm-button'
						onClick={handleSubmit}
						label='Confirm security words'
						icon={<BoltGreen className='bolt-icon'/>}
						disabled={!allFilled}
					/>
				</div>
			</div>
		</div>
	</div>;
};

export default MnemonicInputModal;