import './certificate.scss';
import {useRef, useState} from 'react';
import QRGenerator from '../../utils/gr-generator/QrGenerator';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {ReactComponent as YellowNotiication } from '../../assets/svg/blockmate-info-yellow.svg';
import {ReactComponent as InfoIcon } from '../../assets/svg/info-icon.svg';
import {ReactComponent as BlockmateLogo } from '../../assets/svg/blockmate-logo-black.svg';
import {ReactComponent as DownloadIcon} from '../../assets/svg/download.svg';
import {entropyHexToMnemonic} from '../../utils/withdraw/mnemonic';

const Certificate = ({ entropyHex, handlePdfDownload, isDisabled }) => {
	const [isLoading, setIsLoading] = useState(false);
	const pageRef = useRef(null);
	const mnemonic = entropyHexToMnemonic(entropyHex);

	const handlePdfExport = async () => {
		try {
			setIsLoading(true);

			if (pageRef.current) {
				const capture = pageRef.current;
				const canvas = await html2canvas(capture);
				const imgData = canvas.toDataURL('image/png');

				const doc = new jsPDF('p', 'mm', 'a5', true);
				const componentWidth = doc.internal.pageSize.getWidth();
				const componentHeight = doc.internal.pageSize.getHeight();
				doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
				doc.save('certificate.pdf');

				setIsLoading(false);
				await handlePdfDownload();
			}
		} catch (error) {
			console.error('Error generating or downloading PDF:', error);
			setIsLoading(false);
		}
	};

	const mnemonicWords = () => {
		if (mnemonic) {
			return mnemonic.split(' ').map((word, index) => (
				<span key={index} className='word-element'>
					<span className='word-number'>{`${index + 1}.`}</span>
					<span className='word-text'>{word}</span>
				</span>
			));
		}
	};

	return (
		<>
			<div style={{position: 'absolute', left: -9999, top: -9999}}>
				<div className="page" ref={pageRef}>
					<div className="qr-code-container">
						<div className="qr-description">
							<div>
								<YellowNotiication className='yellow-notification'/>
							</div>
							<div className="description">
								<span className="important-info">Important information</span>
								<span className="info-text">Please keep your Ownership certificate safe, so only you can access your digital assets.</span>
							</div>
						</div>
						<div className="qr-code">
							<div style={{width: 318, height: 318}}>
								<QRGenerator size={318} value={entropyHex}/>
							</div>
							<div className='qr-info'>
								<InfoIcon className='info-icon'/>
								<span className='qr-text'>Private key QR code</span>
							</div>
						</div>
					</div>
					<div className="mnemonic-container">
						<div className="mnemonic-title">
							Mnemonic password · 24 security words for account access
						</div>
						<div className="words-container">
							{mnemonicWords()}
						</div>
						<div className="footer">
							<BlockmateLogo className='blockmate-logo-icon'/>
							<span className="footerText">For assistance, please contact us at blockmate.io</span>
						</div>
					</div>
				</div>
			</div>
			<button className='generate-certificate-button' onClick={handlePdfExport} disabled={isDisabled}>
				<DownloadIcon className='download-icon'/>
				{isLoading
					? 'DOWNLOADING...'
					: 'SEND CERTIFICATE'
				}
			</button>
		</>
	);
};

export default Certificate;
