import './cryptoSavingsIntro.scss';

const IntroDescription = ({children, title, description}) => {
	return (
		<div className='modal-intro-description-content savings-intro-description-content'>
			<div className='icon-container'>
				{children}
			</div>
			<div className='modal-intro-description-title crypto-savings-intro-title'>
				<p className='intro-description-title'>{title}</p>
				<p className='intro-description savings-intro-description'>{description}</p>
			</div>
		</div>
	);
};

export default IntroDescription;

