import '../style.scss';
import {ReactComponent as BlockmateLogo} from '../../../assets/svg/blockmate-logo.svg';
import {ReactComponent as InfoIcon} from '../../../assets/svg/info-icon.svg';
import {ReactComponent as KeyIcon} from '../../../assets/svg/key.svg';
import {ReactComponent as CalendarIcon} from '../../../assets/svg/calendar.svg';
import {ReactComponent as BoltGreen} from '../../../assets/svg/bolt-green.svg';
import ModalFooter from '../../modal-components/modal-footer/ModalFooter';
import MainButton from '../../button/MainButton';
import {useKeyPress} from '../../../hooks/useKeyPress';
import ContentItem from './ContentItem';

const WithdrawAssetsIntro = ({handleNextStep}) => {
	useKeyPress({
		Enter: handleNextStep
	});

	return (
		<>
			<div className='modal-intro-header header-unmargined'>
				<div className='modal-intro-logo'>
					<BlockmateLogo/>
				</div>
				<div className='modal-intro-title'>
					<p>Withdraw digital assets</p>
				</div>
			</div>
			<div className='withdraw-intro-content-container'>
				<ContentItem
					icon={<InfoIcon />}
					title='How it works'
					information={'Your digital assets will be verified, sold and transferred to your main account in it\'s main currency'}
				/>
				<ContentItem
					icon={<KeyIcon />}
					title='Private key verification'
					information='Confirm your withdrawal by filling in Private key from your Ownership certificate'
				/>
				<ContentItem
					icon={<CalendarIcon />}
					title='Confirmation period'
					information='Withdrawal will be processed within 48 hours on average'
				/>
			</div>
			<MainButton
				className='confirm-button continue-btn'
				onClick={handleNextStep}
				label='Continue'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
			<ModalFooter />
		</>
	);
};

export default WithdrawAssetsIntro;