import './select.scss';
import clsx from 'clsx';
import {useState, useRef} from 'react';
import {useOutsideClick} from '../../hooks/useOutsideClick';
import {ReactComponent as Dash} from '../../assets/svg/dash.svg';

const Select = ({
	options,
	placeholder,
	onOptionClick,
	selectedIcon,
	value,
	disabled,
	keySelector,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const ref = useRef(null);

	const handleOptionClick = (option) => {
		setIsDropdownOpen(false);
		onOptionClick(option);
	};

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setIsDropdownOpen(false);
		}
	};

	useOutsideClick(handleClickOutside);

	const handleSelectOpen = () => {
		if (!disabled) {
			setIsDropdownOpen(true);
		}
	};

	return (
		<div className="select">
			<div
				className={clsx(
					'select-input-container',
					isDropdownOpen && 'selected',
					disabled && 'select-disabled',
				)}
				onClick={() => handleSelectOpen()}
			>
				{selectedIcon}
				<input
					value={value}
					placeholder={placeholder}
					className="select-input"
					disabled={disabled}
				/>
				<Dash className={clsx(
					'dash-icon',
					isDropdownOpen && 'dash-icon-reverse',
				)}
				/>
			</div>
			{isDropdownOpen && <div ref={ref} className="select-dropdown">
				{options.map((option, index) => (
					<span
						className="dropdown-item"
						key={index}
						onClick={() => handleOptionClick(option)}
					>
						{option[keySelector].toUpperCase()}
					</span>
				))}
			</div>
			}
		</div>
	);
};

export default Select;
