import styles from './final-message.module.scss';
import Success from '../../../status/Success';
import Error from '../../../status/Error';
import MainButton from '../../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';
import {useEffect, useState} from 'react';
import clsx from 'clsx';

const FinalMessage = ({ message, handleClose, withCounter = true, successEndResult = undefined }) => {
	const CLOSE_AFTER_SECONDS = 10;
	const [untilClose, setUntilClose] = useState(CLOSE_AFTER_SECONDS);
	const isSuccess = message === 'success';

	const handleCloseWithEndResult = () => {
		if (isSuccess && successEndResult) {
			handleClose(successEndResult);
		}
		handleClose();
	};

	const decreaseTimer = () => {
		setUntilClose(prev => {
			if (prev <= 0) {
				handleCloseWithEndResult();
				return 0;
			}
			return prev - 1;
		});
	};

	useEffect(() => {
		if(!withCounter) {
			return;
		}
		const timer = setInterval(() => {
			decreaseTimer();
		}, 1000);
		return () => clearTimeout(timer);
	}, []);
	
	return <div className={styles.container}>
		<div className={styles.topContainer}>
			<div className={styles.statusContainer}>
				{isSuccess ? <Success /> : <Error /> }
			</div>
			<div className={styles.heading}>
				{isSuccess ? 'Success' : 'Error'}
			</div>
			<div className={styles.descriptionContainer}>
				<div className={styles.description}>
					{isSuccess ? 'Your transaction was successfully submitted.' : message}
				</div>
			</div>
			{withCounter && untilClose >= 0 && <div className={styles.closingMessageOuterContainer}>
				<div className={clsx(styles.closingMessageContainer, isSuccess && styles.success, !isSuccess && styles.error)}>
					<div className={styles.text}>
						Closing this window in: {untilClose}s
					</div>
				</div>
			</div>}
		</div>
		<div className={styles.bottomContainer}>
			<MainButton
				className='confirm-button'
				onClick={handleCloseWithEndResult}
				label='Close window'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
		</div>
	</div>;
};

export default FinalMessage;
