import {useEffect} from 'react';

export const useKeyPress = (keyCallbackMap) => {
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (keyCallbackMap && keyCallbackMap[event.key]) {
				keyCallbackMap[event.key]();
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [keyCallbackMap]);
};