import styles from './confirm-permissions.module.scss';
import LinkedLogos from '../../shared/common/LinkedLogos';
import PermissionInfoBox from '../../shared/common/PermissionInfoBox';
import {ReactComponent as EyeIcon} from '../../../../assets/svg/eye.svg';
import {ReactComponent as ArrowsIcon} from '../../../../assets/svg/arrows.svg';
import {ReactComponent as CrossIcon} from '../../../../assets/svg/cross.svg';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';
import MainButton from '../../../button/MainButton';

const ConfirmPermissions = ({ targetInfo, provider, handleNextStep, canProceed = true }) => {
	const permissions = [
		{
			icon: <EyeIcon />,
			title: 'Allow',
			description: 'Viewing wallet balance and activity'
		},
		{
			icon: <ArrowsIcon />,
			title: 'Allow',
			description: 'Sending requests for transactions'
		},
		{
			icon: <CrossIcon />,
			title: 'Don\'t allow',
			description: 'Moving funds without your permission'
		}
	];

	return <div className={styles.container}>
		<div className={styles.headerContainer}>
			<div className={styles.imageSection}>
				<LinkedLogos
					leftLogo={<img src={provider?.icon} className={styles.logoImg} alt='provider-logo' />}
					rightLogo={<img src={targetInfo?.icon} className={styles.logoImg} alt='target-logo' />}
				/>
			</div>
			<div className={styles.textSection}>
				Link {provider?.description} to {targetInfo?.description}
			</div>
		</div>
		<div className={styles.contentContainer}>
			{permissions.map((permission, index) => <div key={`permission-box-${index}`}>
				<PermissionInfoBox
					icon={permission?.icon}
					title={permission?.title}
					description={permission?.description}
				/>
			</div>)}
		</div>
		<div>
			<MainButton
				className='confirm-button'
				onClick={handleNextStep}
				label='Continue'
				icon={<BoltGreen className='bolt-icon' />}
				disabled={!canProceed}
			/>
		</div>
	</div>;
};

export default ConfirmPermissions;