import {chunkArray} from '../../../utils/utils';
import MnemonicSingleWordInput from './MnemonicSingleWordInput';

const MnemonicPhraseInput = ({ mnemonic, setMnemonic, columns = 3 }) => {
	const mnemonicLength = mnemonic.length;

	const wordsPerColumn = Math.ceil(mnemonicLength / columns);
	const wordIndicesByColumn = chunkArray([...Array(mnemonicLength).keys()], wordsPerColumn);

	const handleChange = (value, index) => {
		setMnemonic(prevMnemonic => {
			const newMnemonic = [...prevMnemonic];
			newMnemonic[index] = value;
			return newMnemonic;
		});
	};

	return <div className='mnemonic-words-inputs-container'>
		<div className='row'>
			{wordIndicesByColumn.map((wordIndices, columnIndex) =>
				<div className='column' key={columnIndex}>
					{wordIndices.map((wordIndex) =>
						<div key={`${columnIndex}-${wordIndex}`} className='row'>
							<MnemonicSingleWordInput
								key={wordIndex}
								wordIndex={wordIndex + 1}
								onChange={(value) => handleChange(value, wordIndex)}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	</div>;
};

export default MnemonicPhraseInput;