import useApiInstances from './axios';

const useDirectDeposit = (jwt) => {
	const {apiBlockmate} = useApiInstances();
	const headers = {'Authorization': `Bearer ${jwt}`};

	const initialize = async (fiatAmount, fiatCurrency) => {
		const body = {
			fiat_amount: fiatAmount,
			fiat_currency: fiatCurrency,
		};
		const res = await apiBlockmate.post(
			'v1/exchange/deposit/direct/initialize',
			body,
			{headers}
		);
		return res?.data?.deposit_id;
	};

	const simpleOverview = async (depositId) => {
		const res = await apiBlockmate.get(
			`v1/exchange/deposit/direct/${depositId}/simple-overview`,
			{headers}
		);
		return res?.data;
	};

	const overview = async (depositId) => {
		const response = await apiBlockmate.get(
			`/v1/exchange/deposit/direct/${depositId}/overview`,
			{headers}
		);
		const data = response?.data;
		return {
			authChallenge: data?.challenge,
			supportedCurrencies: data?.supported_currencies,
		};
	};

	const authorize = async (depositId, address, signature, currency, network) => {
		const response = await apiBlockmate.post(
			`/v1/exchange/deposit/direct/${depositId}/authorize`,
			{address, signature, crypto_currency: currency, network},
			{
				headers: {'Authorization': `Bearer ${jwt}`}
			},
		);
		const data = response?.data;
		return {
			depositAddress: data?.deposit_address,
			cryptoAmount: data?.crypto_amount,
		};
	};

	const finish = async (depositId, transactionId) => {
		await apiBlockmate.post(
			`/v1/exchange/deposit/direct/${depositId}/finish`,
			{transaction_id: transactionId},
			{headers},
		);
	};

	const check = async (depositId) => {
		const response = await apiBlockmate.get(
			`/v1/exchange/deposit/direct/${depositId}/finish`,
			{headers},
		);
		return response?.data;
	};

	return {
		initialize,
		simpleOverview,
		overview,
		authorize,
		finish,
		check,
	};
};

export default useDirectDeposit;
