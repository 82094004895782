import styles from './permission-info-box.module.scss';

const PermissionInfoBox = ({icon, title, description}) => {
	return <div className={styles.container}>
		<div className={styles.iconContainer}>
			{icon}
		</div>
		<div className={styles.contentContainer}>
			<div className={styles.title}>
				{title}
			</div>
			<div className={styles.description}>
				{description}
			</div>
		</div>
	</div>;
};

export default PermissionInfoBox;
