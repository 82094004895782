import {ReactComponent as BugIcon} from '../../assets/svg/bug-icon.svg';
import {ReactComponent as ExitIcon} from '../../assets/svg/exit-icon.svg';
import './style.scss';
import appRoutes from '../../routes/routes';

const ErrorValidation = ({onClick, withExitIcon, errorData}) => {
	const {external} = appRoutes;

	return (
		<>
			{withExitIcon && (
				<div className='validation-error-close-container'>
					<ExitIcon className='validation-error-close-icon' onClick={onClick}/>
				</div>
			)}
			<div className='validation-error-container'>
				<BugIcon className='bug-icon'/>
				<p className='validation-error-code'>
					{errorData ? `${errorData.status}: ${errorData.data.detail}` : '403: Invalid token'}
				</p>
				<p className='validation-error-text'>For reference, please check the </p>
				<a
					href={external.documentation}
					target={'_blank'} rel="noreferrer"
					className='validation-error-text error-documentation'
				>
						documentation →
				</a>
			</div>
		</>
	);
};

export default ErrorValidation;
