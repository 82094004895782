import './style.scss';

const OrLine = () => (
	<div className='or-main-container'>
		<div className='or-line-container'>
			<div className='or-line-text'>OR</div>
		</div>
	</div>
);

export default OrLine;