import './style.scss';
import MainButton from '../../button/MainButton';
import {useEffect, useRef} from 'react';
import {useKeyPress} from '../../../hooks/useKeyPress';
import {ReactComponent as BlockmateLogo} from '../../../assets/svg/blockmate-logo.svg';
import {ReactComponent as ChainIcon} from '../../../assets/svg/chain-icon.svg';
import {ReactComponent as PrivacyGuardIcon} from '../../../assets/svg/privacy-guard-icon.svg';
import {ReactComponent as ExitIcon} from '../../../assets/svg/exit-icon.svg';
import {ReactComponent as BoltGreen} from '../../../assets/svg/bolt-green.svg';

const Intro = ({onClick, handleClose}) => {

	const continueButtonRef = useRef();

	useKeyPress({
		Escape: handleClose
	});

	useEffect(() => {
		if (continueButtonRef.current) {
			continueButtonRef.current.focus();
		}
	}, []);

	return (
		<>
			<ExitIcon className='exit-modal' onClick={handleClose}/>
			<div className='modal-intro-header'>
				<div className='modal-intro-logo'>
					<BlockmateLogo/>
				</div>
				<div className='modal-intro-title'>
					<p>Connect your Web3</p>
					<p>accounts with Blockmate</p>
				</div>
			</div>
			<div className='modal-intro-descriptions-container'>
				<div className='modal-intro-description-content'>
					<div className='modal-intro-description-title'>
						<ChainIcon src={ChainIcon} alt='chain icon'/>
						<p className='intro-description-title'>Simple and secure</p>
					</div>
					<div className='modal-intro-description'>
						<p className='intro-description'>Blockmate connects your Web3 accounts in just a few clicks</p>
					</div>
				</div>
				<div className='modal-intro-description-content'>
					<div className='modal-intro-description-title'>
						<div className='icon'>
							<PrivacyGuardIcon alt='privacy guard icon'/>
						</div>
						<p className='intro-description-title'>Control what you share</p>
					</div>
					<div className='modal-intro-description'>
						<p className='intro-description'>We never share your data without your permission</p>
					</div>
				</div>
			</div>
			<MainButton
				innerRef={continueButtonRef}
				className='confirm-button'
				onClick={onClick}
				label='Continue'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
		</>
	);
};

export default Intro;