import './change-phone.scss';
import Input from '../input/Input';
import Counter from '../counter/Counter';
import MainButton from '../button/MainButton';
import {ReactComponent as ResendIcon} from '../../assets/svg/resend.svg';
import {ReactComponent as BoltGreen} from '../../assets/svg/bolt-green.svg';

const ConfirmPhone = ({handleCode, invalidCode, counter, setCounter, authorizePhoneNumber, code, initializePhone}) => {
	const codeMaxLength = 6;

	return (
		<div className='content confirm'>
			<div className='title'>
				Confirm phone number
			</div>
			<p className='description'>
				Please provide SMS code
			</p>
			<div className='with-counter'>
				<Input
					onChange={ handleCode }
					type='text'
					className='provider-input'
					placeholder='SMS code'
					maxLength={codeMaxLength}
				/>
				{invalidCode && (
					<div className='error-text'>
						Wrong code
					</div>
				)}
				<Counter counter={ counter } setCounter={ setCounter }/>
			</div>
			<MainButton
				className='confirm-button'
				onClick={ authorizePhoneNumber }
				disabled={ code?.length !== codeMaxLength || counter === 0}
				label='Confirm'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
			<div className='resend' onClick={ initializePhone }>
				<ResendIcon/>
				<p className='link'>Resend SMS code</p>
			</div>
		</div>
	);
};

export default ConfirmPhone;