import {QrScanner} from '@yudiel/react-qr-scanner';
import { ReactComponent as InfoIcon } from '../../assets/svg/info-icon.svg';
import {useState} from 'react';
import {entropyHexToMnemonic, mnemonicToSeedHex} from '../../utils/withdraw/mnemonic';

const WithdrawPrivateKeyQR = ({ handleSeed, handleNextStep }) => {
	const [error, setError] = useState(null);


	// We store entropy in the QR code
	const onQrDecoded = (decoded) => {
		setError(null);
		const mnemonic = entropyHexToMnemonic(decoded);
		const seed = mnemonicToSeedHex(mnemonic);
		handleSeed(seed);
		handleNextStep();
	};

	return <div className='qr-screen-container'>
		<div className='qr-heading'>
			Ownership certificate QR code
		</div>
		<div className='qr-reader'>
			<QrScanner onError={setError} onDecode={onQrDecoded} />
		</div>
		{error && <div className='qr-error'>
			{error}
		</div>
		}
		<div className='qr-info'>
			<div className='qr-info-icon'>
				<InfoIcon />
			</div>
			<div className='qr-info-text'>
				Scan QR code to copy Private key
			</div>
		</div>
	</div>;
};

export default WithdrawPrivateKeyQR;