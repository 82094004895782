import CryptoSavings from './CryptoSavings';
import CryptoSavingsContextProvider from '../../context/crypto-savings/CryptoSavingsContext';

const CryptoSavingsWrapper = () => {
	return (
		<CryptoSavingsContextProvider>
			<CryptoSavings/>
		</CryptoSavingsContextProvider>
	);
};

export default CryptoSavingsWrapper;