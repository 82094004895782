import {useContext} from 'react';
import {CryptoSavingsContext} from '../context/crypto-savings/CryptoSavingsContext';

function useEntropyGeneration() {
	const {entropy, setEntropy} = useContext(CryptoSavingsContext);

	const generateRandomValue = () => {
		const randomBytes = new Uint8Array(32);
		window.crypto.getRandomValues(randomBytes);
		return Array.from(randomBytes, (byte) => byte.toString(16).padStart(2, '0')).join('');
	};

	const xorOperation = (aHex, bHex) => {
		if (aHex.length !== bHex.length) {
			throw new Error('XOR requires operands of the same length');
		}

		const aBuf = Buffer.from(aHex, 'hex');
		const bBuf = Buffer.from(bHex, 'hex');
		const xorBuf = Buffer.alloc(aBuf.length);
		for (let i = 0; i < aBuf.length; i++) {
			xorBuf[i] = aBuf[i] ^ bBuf[i];
		}
		return xorBuf.toString('hex');
	};

	const generateEntropy = (serverEntropy) => {
		try {
			if (serverEntropy) {
				const randomValue = generateRandomValue();
				const combinedEntropy = xorOperation(serverEntropy, randomValue);
				setEntropy(combinedEntropy);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return {
		entropy,
		generateEntropy,
	};
}

export default useEntropyGeneration;
