const ContentItem = ({icon, title, information}) => (
	<div className='withdraw-content-item-container'>
		<div className='icon-container'>
			{icon}
		</div>
		<div className='text-container'>
			<div className='item-title'>
				{title}
			</div>
			<div className='item-information'>
				{information}
			</div>
		</div>
	</div>
);

export default ContentItem;