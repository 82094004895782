import './cryptoSavingsFrequency.scss';
import MainButton from '../../button/MainButton';
import FrequencyRow from './FrequencyRow';
import dayjs from 'dayjs';
import {useState} from 'react';
import {useContext} from 'react';
import {CryptoSavingsContext} from '../../../context/crypto-savings/CryptoSavingsContext';
import {DateCalendar, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {frequencies} from '../../../utils/savings';
import {ReactComponent as BoltGreen} from '../../../assets/svg/bolt-green.svg';
import {ReactComponent as CalendarIcon} from '../../../assets/svg/calendar.svg';
import {ReactComponent as ResendIcon} from '../../../assets/svg/resend.svg';

const frequencyOptions = [
	{ id: 0, label: 'Daily' },
	{ id: 1, label: 'Weekly' },
	{ id: 2, label: 'Monthly' },
	{ id: 3, label: 'One-time buy' }
];

const CryptoSavingFrequency = () => {
	const {
		setStartDate,
		startDate,
		setStep,
		frequency,
		setFrequency,
		setFrequencyDescription,
		STEPS
	} = useContext(CryptoSavingsContext);
	const [isCalendarView, setIsCalendarView] = useState(false);
	const [isFrequencyView, setIsFrequencyView] = useState(false);

	const [value, setValue] = useState(startDate? startDate : dayjs());

	const handleStartDate = () => {
		setStartDate(value);
		setIsCalendarView(false);
	};

	const handleSetRepeat = () => {
		setIsFrequencyView(false);
	};

	const handleFrequency = (e) => {
		setFrequency(e);
	};

	// TODO function in utils

	const months = [
		'January', 'February', 'March', 'April', 'May', 'June',
		'July', 'August', 'September', 'October', 'November', 'December'
	];

	const days = ['Sundays', 'Mondays', 'Tuesdays', 'Wednesdays', 'Thursdays', 'Fridays', 'Saturdays'];

	const parseDate = (value) => {
		const day = value?.$D;

		const monthName = months[value?.$M];

		return `${day} ${monthName}`;
	};

	const startDateParsed = startDate && parseDate(startDate);

	const getMoreInfo = () => {
		const descriptions = [
			`, from ${startDateParsed}`,
			`, on ${days[value.day()]}`,
			`, on every ${getOrdinalNumber(value?.$D)}`,
		];

		const description = descriptions[frequency] || '';
		setFrequencyDescription(description);
	};

	const getOrdinalNumber = (day) => {
		const suffixes = ['st', 'nd', 'rd'];
		const suffix = day > 3 ? 'th' : suffixes[day - 1] || 'th';
		return `${day}${suffix}`;
	};


	const confirmChanges = () => {
		getMoreInfo();
		setStep(STEPS.NEW_CRYPTO_SAVING);
	};

	if (isCalendarView) {
		return (
			<div className='crypto-savings'>
				<div className='create-saving-title'>
					Start date
				</div>
				<div className='calendar-wrapper'>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DateCalendar
							value={value}
							onChange={(newValue) => setValue(newValue)}
							disablePast
						/>
					</LocalizationProvider>
				</div>
				<MainButton
					className='confirm-button'
					onClick={handleStartDate}
					label='Set start date'
					icon={<BoltGreen className='bolt-icon'/>}
				/>
			</div>
		);
	}

	if (isFrequencyView) {
		return (
			<div className='crypto-savings'>
				<div className='create-saving-title'>
						Repeats
				</div>
				<div className='frequencies-container'>
					{frequencyOptions.map(option => (
						<FrequencyRow
							key={option.id}
							onClick={() => handleFrequency(option.id)}
							checked={frequency === option.id}
						>
							{option.label}
						</FrequencyRow>
					))}
				</div>
				<MainButton
					className='confirm-button'
					onClick={handleSetRepeat}
					label='Set repeat'
					icon={<BoltGreen className='bolt-icon'/>}
				/>
			</div>
		);
	}

	return (
		<div className='crypto-savings'>
			<div className='create-saving-title'>
				Set frequency
			</div>
			<div className='inputs-container'>
				<div className='crypto-savings-input'>
					<span>Start date</span>
					<div onClick={() => {setIsCalendarView(true);}} className='calendar-frequency-container'>
						<CalendarIcon className='calendar-icon'/>
						{startDateParsed}
					</div>
				</div>
				<div className='crypto-savings-input'>
					<span>Repeats</span>
					<div onClick={() => {setIsFrequencyView(true);}} className='calendar-frequency-container'>
						<ResendIcon className='calendar-icon'/>
						{frequency !== null ? `${frequencies[frequency]}` : 'Set frequency'}
					</div>
				</div>
			</div>
			<MainButton
				className='confirm-button'
				onClick={confirmChanges}
				label='Schedule'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
		</div>
	);
};

export default CryptoSavingFrequency;