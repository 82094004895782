export default async function importPublicKey(publicKeyPEM) {
	const pemHeader = '-----BEGIN PUBLIC KEY-----';
	const pemFooter = '-----END PUBLIC KEY------';
	const pemContents = publicKeyPEM.slice(pemHeader.length, -pemFooter.length);
	const binaryDer = atob(pemContents);
	const binaryDerBuffer = new Uint8Array(new ArrayBuffer(binaryDer.length));

	for (let i = 0; i < binaryDer.length; i++) {
		binaryDerBuffer[i] = binaryDer.charCodeAt(i);
	}

	return await crypto.subtle.importKey(
		'spki',
		binaryDerBuffer,
		{
			name: 'RSA-OAEP',
			hash: { name: 'SHA-256' }
		},
		true,
		['encrypt']
	);
}
