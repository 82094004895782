import './verify-phone.scss';
import Input from '../input/Input';
import Counter from '../counter/Counter';
import MainButton from '../button/MainButton';
import ModalFooter from '../modal-components/modal-footer/ModalFooter';
import {ReactComponent as ResendIcon} from '../../assets/svg/resend.svg';

const VerifyConfirmPhone = ({handleCode, invalidCode, counter, setCounter, authorizePhoneNumber, code, initializePhone}) => {
	return (
		<div className='content confirm'>
			<div className='title'>
				Confirm phone number
			</div>
			<p className='description'>
				Please provide SMS code
			</p>
			<div className='with-counter'>
				<Input
					onChange={handleCode}
					type='text'
					className='provider-input'
					placeholder='SMS code'
					maxLength='6'
				/>
				{invalidCode && (
					<div className='error-text'>
						Wrong code
					</div>
				)}
				<Counter counter={counter} setCounter={setCounter}/>
			</div>
			<MainButton
				className='confirm-button'
				onClick={authorizePhoneNumber}
				disabled={code?.length !== 6 || counter === 0}
				label='Continue'
			/>
			<div className='resend' onClick={initializePhone}>
				<ResendIcon/>
				<p className='link'>Resend SMS code</p>
			</div>
			<ModalFooter/>
		</div>
	);
};

export default VerifyConfirmPhone;