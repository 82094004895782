import {ReactComponent as SecurityWerified} from '../../assets/svg/security-verified.svg';
import useInterval from '../../hooks/useInterval';
import { useState } from 'react';

const CryptoVerificationSuccess = ({handleClose}) => {
	const [counter, setCounter] = useState(15);

	useInterval(() => {
		if (counter > 0) {
			setCounter(prevCounter => prevCounter - 1);
		} else {
			handleClose();
		}
	}, 1000);

	return (
		<div className='modal-content saving-success'>
			<div className='success-content'>
				<div className='success-logo'>
					<SecurityWerified/>
				</div>
				<div className='success-text'>
					<div className='success-title'>
						<p>Your digital assets order was successfully confirmed!</p>
					</div>
					<p className='success-description'>
						As a reminder, please store your Ownership certificate in a safe place! It ensures only you have a full control of your digital assets saving account.
					</p>
					<div className='closing-counter'>
						<p className='counter-text'>
              Safely closing this window in {counter}s
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CryptoVerificationSuccess;