import './style.scss';
import React, {useEffect} from 'react';
import ModalFooter from '../modal-components/modal-footer/ModalFooter';
import Intro from '../modal-components/modal-intro/ModalIntro';
import SearchProviderAmlDemo from '../modal-components/search-provider/SearchProviderAmlDemo';
import Form from '../modal-components/form/ConnectProvider';
import {useState} from 'react';
import {ProviderContext} from '../../context/provider/ProviderContext';
import {useSearchParams} from 'react-router-dom';
import Loading from '../status/loading';
import ErrorValidation from '../errors/ErrorValidation';
import WalletConnect from '../modal-components/wallet-connect/WalletConnect';
import WalletConnectChainSelector from '../modal-components/wallet-connect/WalletConnectChainSelector';
import useApiInstances from '../../services/api-call/axios';
import {handleClose} from 'blockmate-react-link';

export const STEPS = {
	intro: 1,
	searchManualProvider: 2,
	searchWalletConnectProvider: 3,
	walletConnectShowQR: 4,
	manualShowForm: 5
};

const PREV_STEP = {
	[STEPS.intro]: STEPS.intro,
	[STEPS.searchWalletConnectProvider]: STEPS.searchManualProvider,
	[STEPS.searchManualProvider]: STEPS.intro,
	[STEPS.walletConnectShowQR]: STEPS.searchWalletConnectProvider,
	[STEPS.manualShowForm]: STEPS.searchManualProvider
};

const ModalAmlDemo = () => {
	const OAUTH_CONNECTED_PARAM = 'oauthConnectedAccount';

	const [searchParams, setSearchParams] = useSearchParams();
	const [step, setStep] = useState(STEPS.intro);
	const [provider, setProvider] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [manualProvidersList, setManualProvidersList] = useState([]);
	const [walletConnectProvidersList, setWalletConnectProvidersList] = useState([]);
	const [validationCorrect, setValidationCorrect] = useState(true);
	const walletConnectSupportedChains = ['eth'];
	const {apiLink} = useApiInstances();

	const token = searchParams.get('jwt');
	const oauthConnectedAccount = searchParams.get(OAUTH_CONNECTED_PARAM);

	useEffect(() => {
		if (oauthConnectedAccount) {
			setStep(STEPS.manualShowForm);
		}
	}, []);

	useEffect(() => {
		const controller = new AbortController();
		const getProviders = async () => {
			try {
				const response = await apiLink.get('/providers');
				if (response.status === 200) {
					const providers = response.data;
					const oauthProviders = [];

					for (let i = 0; i < providers.length; i++) {
						if (providers[i].oauth === true) {
							oauthProviders.push({
								'name': providers[i].name,
								'url': 'oauth/' + providers[i].name,
								'icon': providers[i].icon,
								'description': providers[i].description,
								'oauth': true
							});
						}
					}

					providers.push.apply(providers, oauthProviders);
					setManualProvidersList(providers);

					// Set wallet-connect providers
					const walletConnectProviders = providers
						.filter(provider => provider.url.includes('onchain/') && walletConnectSupportedChains.includes(provider.name))
						.map(provider => {
							return {
								...provider,
								url: `wallet-connect/${provider.name}`,
							};
						});
					setWalletConnectProvidersList(walletConnectProviders);
				}

				setIsLoading(false);
			} catch (error) {
				if (error.message !== 'canceled') {
					setIsLoading(false);
					setValidationCorrect(false);
				}
			}
		};

		getProviders().catch(console.error);

		return () => {
			controller.abort();
		};
	}, []);

	const handleBackArrow = () => {
		setStep(prevState => PREV_STEP[prevState]);
	};

	if (isLoading) return (
		<div className='modal-container'>
			<div className='modal-content'>
				<div className='content-wrapper'>
					<Loading/>
				</div>
			</div>
		</div>
	);

	if (!validationCorrect) return (

		<div className='modal-container'>
			<div className='modal-content'>
				<ErrorValidation onClick={() => handleClose()} withExitIcon/>
			</div>
		</div>
	);

	return (
		<ProviderContext.Provider value={{provider, setProvider}}>
			<div className='modal-container'>
				<div className='modal-content'>
					{step === STEPS.intro &&
						<Intro step={step} onClick={() => setStep(STEPS.searchManualProvider)} handleClose={() => handleClose()}/>
					}
					{step === STEPS.searchManualProvider &&
						<SearchProviderAmlDemo
							step={step}
							onClick={() => setStep(STEPS.manualShowForm)}
							onButtonClick={() => setStep(STEPS.searchWalletConnectProvider)}
							providersData={manualProvidersList}
							handleClose={() => handleClose()}
							handleBackArrow={handleBackArrow}
						/>
					}
					{step === STEPS.searchWalletConnectProvider &&
						<WalletConnectChainSelector
							onClick={() => setStep(STEPS.walletConnectShowQR)}
							providersData={walletConnectProvidersList}
							handleClose={() => handleClose()}
							handleBackArrow={handleBackArrow}
						/>
					}
					{step === STEPS.manualShowForm &&
						<Form
							setStep={setStep}
							token={token}
							oauthConnectedParam={OAUTH_CONNECTED_PARAM}
							oauthConnectedAccount={oauthConnectedAccount}
							handleClose={(endResult) => handleClose(endResult)}
							handleBackArrowSuccess={() => {
								setStep(STEPS.intro);
								searchParams.delete(OAUTH_CONNECTED_PARAM);
								setSearchParams(searchParams);
							}}
							handleBackArrowNoSuccess={handleBackArrow}
						/>
					}
					{step === STEPS.walletConnectShowQR &&
						<WalletConnect
							provider={provider}
							token={token}
							onCancel={() => setStep(STEPS.searchWalletConnectProvider)}
							handleClose={(endResult) => handleClose(endResult)}
							handleBackArrow={handleBackArrow}
						/>
					}
					{step === STEPS.intro && <ModalFooter/>}
				</div>
			</div>
		</ProviderContext.Provider>
	);
};
export default ModalAmlDemo;
