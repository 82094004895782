import axios from 'axios';
import {networks, Psbt} from 'bitcoinjs-lib';
import {encode, encodingLength} from 'varuint-bitcoin';
import {ECPairFactory} from 'ecpair';
import {createHash} from 'crypto';
import * as tinysecp from '@bitcoin-js/tiny-secp256k1-asmjs';
import {getNetworkFromEnv} from './mnemonic';

const ECPair = ECPairFactory(tinysecp);

// Network parameters
const MAINNET_FEE_SAT = 1920;
const TESTNET_FEE_SAT = 192;
const SATS_IN_COIN = 100000000;

const BLOCKSTREAM_URL = 'https://blockstream.info';

//https://github.com/Eunovo/scripting-with-bitcoinjs/blob/main/src/witness_stack_to_script_witness.ts
const witnessStackToScriptWitness = (witness) => {
	let buffer = Buffer.allocUnsafe(0);

	const writeSlice = (slice) => {
		buffer = Buffer.concat([buffer, Buffer.from(slice)]);
	};

	const writeVarInt = (i) => {
		const currentLen = buffer.length;
		const varintLen = encodingLength(i);

		buffer = Buffer.concat([buffer, Buffer.allocUnsafe(varintLen)]);
		encode(i, buffer, currentLen);
	};

	const writeVarSlice = (slice) => {
		writeVarInt(slice.length);
		writeSlice(slice);
	};

	const writeVector = (vector) => {
		writeVarInt(vector.length);
		vector.map(writeVarSlice);
	};

	writeVector(witness);
	return buffer;
};

const getFinalizeCallback = (redeemScriptBuffer) => {
	const finalizeCallback = (_, input) => {
		// witness should look like this:
		// 0. entry: ''
		// 1. entry: <client's signature> - this will be added by the client
		// 2. entry: <user's signature>
		// 3. entry: '' (to signalize this is main multi-signature branch and not the recovery one) - this will be added by the client
		// 4. entry: <redeem script>
		const finalScriptWitness = witnessStackToScriptWitness(
			[Buffer.from(''), input.partialSig[0].signature, redeemScriptBuffer]
		);

		return {
			finalScriptSig: Buffer.from(''),
			finalScriptWitness
		};
	};
	return finalizeCallback;
};

const getAddressUtxos = async (address, network) => {
	let url = `${BLOCKSTREAM_URL}/testnet/api/address/${address}/utxo`;
	if (network === networks.bitcoin) {
		url = `${BLOCKSTREAM_URL}/api/address/${address}/utxo`;
	}
	const response = await axios.get(url);
	return response.data;
};

const getBalance = (utxos) => {
	return utxos.reduce((sum, utxo) => sum + utxo.value, 0);
};

const signTransaction = ({
	sourceAddress,
	destinationAddress,
	utxos,
	redeemScript,
	balanceSat,
	amountSat,
	feeSat,
	privateKeyHex,
	network
}) => {
	const psbt = new Psbt({network: network});

	psbt.addOutput({
		address: destinationAddress,
		value: amountSat
	});

	if (balanceSat - amountSat - feeSat > 0) {
		psbt.addOutput({
			address: sourceAddress,
			value: balanceSat - amountSat - feeSat
		});
	}

	const redeemScriptBuffer = Buffer.from(redeemScript, 'hex');
	const redeemHashBuffer = createHash('sha256').update(redeemScriptBuffer).digest();

	//script in P2WSH is 0x00 + <SHA-256 hash of redeem script>. Hash is 32 bytes long which is 0x20 in hex
	const scriptBuffer = Buffer.concat([Buffer.from('0020', 'hex'), redeemHashBuffer]);
	const privateKeyBuffer = Buffer.from(privateKeyHex, 'hex');
	const privateKey = ECPair.fromPrivateKey(privateKeyBuffer, {network: network});

	utxos.forEach((utxo, index) => {
		psbt.addInput({
			hash: utxo.txid,
			index: utxo.vout,
			witnessUtxo: {
				script: scriptBuffer,
				value: utxo.value
			},
			witnessScript: redeemScriptBuffer
		});

		psbt.signInput(index, privateKey);
		psbt.finalizeInput(index, getFinalizeCallback(redeemScriptBuffer));
	});

	return psbt.extractTransaction().toHex();
};

export const prepareAndSignTransaction = async ({
	sourceAddress,
	destinationAddress,
	redeemScript,
	amount,
	privateKeyHex,
}) => {
	const network = getNetworkFromEnv();
	const utxos = await getAddressUtxos(sourceAddress, network);
	
	const balanceSat = getBalance(utxos);
	const amountSat = Math.floor(amount * SATS_IN_COIN);
	const feeSat = network === networks.bitcoin ? MAINNET_FEE_SAT : TESTNET_FEE_SAT;

	if (amountSat + feeSat > balanceSat) {
		throw new Error('Insufficient balance for the transaction, current balance is: ' + balanceSat / SATS_IN_COIN);
	}

	return signTransaction({
		sourceAddress,
		destinationAddress,
		utxos,
		redeemScript,
		balanceSat,
		amountSat,
		feeSat,
		privateKeyHex,
		network
	});
};
