import Input from '../input/Input';
import ModalFooter from '../modal-components/modal-footer/ModalFooter';
import MainButton from '../button/MainButton';
import {ReactComponent as BoltGreen} from '../../assets/svg/bolt-green.svg';
import {useKeyPress} from '../../hooks/useKeyPress';

const WithdrawCheckData = ({fiatCurrency, cryptoAmount, cryptoCurrency, handleNextStep, invalidConfirmation}) => {
	useKeyPress({
		Enter: handleNextStep
	});

	return (
		<div className='content transfer-assets'>
			<div className='title'>
				Transfer assets · Step 2/3
			</div>
			<p className='description'>
				Please double-check transaction details.
			</p>
			<div className='amount-input'>
				<Input
					type='text'
					id='filter'
					className='provider-input'
					value={`Sell: ${cryptoAmount} ${cryptoCurrency}`}
					readOnly
				/>
			</div>
			<Input
				type='text'
				id='filter'
				className='provider-input input-margin'
				placeholder='Fiat currency'
				value={`Withdraw in: ${fiatCurrency}`}
				readOnly
			/>
			<MainButton
				className='confirm-button btn-margin-top'
				onClick={handleNextStep}
				label='Confirm details'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
			{invalidConfirmation && (
				<div className='error-text'>
					{invalidConfirmation}
				</div>
			)}
			<ModalFooter/>
		</div>
	);
};

export default WithdrawCheckData;