import Input from '../input/Input';
import MainButton from '../button/MainButton';
import ModalFooter from '../modal-components/modal-footer/ModalFooter';
import {ReactComponent as BoltGreen} from '../../assets/svg/bolt-green.svg';

const TransferCheckData = ({
	currency,
	destinationAddress,
	amount,
	handleConfirm,
	invalidConfirmation
}) => {
	return (
		<div className='content transfer-assets'>
			<div className='title'>
				Transfer assets · Step 2/3
			</div>
			<p className='description'>
				Please double-check you are transferring your assets to the right address and blockchain. Asset loss is irreversible.
			</p>
			<Input
				type='text'
				id='filter'
				className='provider-input input-margin'
				placeholder='Choose asset'
				value={currency}
				readOnly
			/>
			<Input
				type='text'
				id='filter'
				className='provider-input input-margin'
				placeholder='Account/Address'
				value={destinationAddress}
				readOnly
			/>
			<Input
				type='text'
				id='filter'
				className='provider-input'
				value={amount}
				readOnly
			/>
			<MainButton
				className='confirm-button'
				onClick={handleConfirm}
				label='Confirm details'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
			{invalidConfirmation && (
				<div className='error-text'>
					{invalidConfirmation}
				</div>
			)}
			<ModalFooter/>
		</div>
	);
};

export default TransferCheckData;
