import './verify-phone.scss';
import ModalHeader from '../modal-components/modal-header/ModalHeader';
import useApiInstances from '../../services/api-call/axios';
import VerifySetPhone from './VerifySetPhone';
import VerifyConfirmPhone from './VerifyConfirmPhone';
import useStepNavigation from '../../hooks/useStepNavigation';
import LoadingScreen from '../status/LoadingScreen';
import { useState } from 'react';
import {handleClose} from 'blockmate-react-link';
import BlockmateLogoBlack from '../../assets/svg/blockmate-logo-black.svg';

export const STEPS = {
	SET_PHONE: 1,
	CONFIRM: 2,
};

const VerifyPhoneModal = () => {
	const [phone, setPhone] = useState(null);
	const [code, setCode] = useState(null);
	const [invalidData, setInvalidData] = useState(null);
	const [invalidCode, setInvalidCode] = useState(false);
	const [counter, setCounter] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const {apiLink} = useApiInstances();
	const {step, handleBackArrow, handleNextStep} = useStepNavigation(STEPS.SET_PHONE);

	const initializePhone = async () => {
		setIsLoading(true);
		try {
			const response = await apiLink.post('number/initialize', { 'phone_number': phone });
			if (response.status === 200) {
				setCounter(60);
				if (step === STEPS.SET_PHONE) {
					handleNextStep();
				}
			}
		} catch (error) {
			setInvalidData(true);
		}
		setIsLoading(false);
	};

	const handleCode = (e) => {
		setInvalidCode(false);
		setCode(e.target.value);
	};

	const authorizePhoneNumber = async() => {
		setIsLoading(true);
		try {
			const response = await apiLink.post('number/authorize', { 'phone_number': phone, 'otp': code });
			if (response.status === 200) {
				setInvalidCode(false);
				handleClose(response.data.callback);
			}
		} catch (error) {
			setInvalidCode(true);
		}
		setIsLoading(false);
	};

	if (isLoading) return <LoadingScreen/>;

	return (
		<div className='modal-container'>
			<div className='modal-content phone-modal'>
				<ModalHeader
					handleBackArrow={handleBackArrow}
					handleClose={() => handleClose()}
					providerLogo={BlockmateLogoBlack}
					withArrow={step === STEPS.CONFIRM}
				/>
				{step === STEPS.SET_PHONE && (
					<VerifySetPhone
						invalidData={invalidData}
						setInvalidData={setInvalidData}
						setPhone={setPhone}
						phone={phone}
						initializePhone={initializePhone}
					/>
				)}
				{step === STEPS.CONFIRM && (
					<VerifyConfirmPhone
						handleCode={handleCode}
						invalidCode={invalidCode}
						counter={counter}
						setCounter={setCounter}
						authorizePhoneNumber={authorizePhoneNumber}
						code={code}
						initializePhone={initializePhone}
					/>
				)}
			</div>
		</div>
	);
};

export default VerifyPhoneModal;
