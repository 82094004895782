import './style.scss';
import {useContext, useRef} from 'react';
import {ProviderContext} from '../../../context/provider/ProviderContext';
import ModalHeader from '../modal-header/ModalHeader';
import BlockmateLogoBlack from '../../../assets/svg/blockmate-logo-black.svg';
import { ReactComponent as WalletConnectIcon } from '../../../assets/svg/wallet-connect.svg';
import SearchCategory from '../common/SearchCategory';
import {useKeyboardNavigation} from '../../../hooks/useKeyboardNavigation';
import {useKeyPress} from '../../../hooks/useKeyPress';
import MainButton from '../../button/MainButton';

const SearchProviderAmlDemo = ({onClick, onButtonClick, providersData, handleBackArrow, handleClose}) => {
	const filter = '';
	const {setProvider} = useContext(ProviderContext);
	const searchBoxRef = useRef();

	const ORDERED_CATEGORIES = ['exchange', 'oauth', 'onchain'];

	const setSearchBoxFocus = (setFocused) => {
		if (setFocused) {
			searchBoxRef.current.focus();
		} else {
			searchBoxRef.current.blur();
		}
	};

	useKeyPress(
		{
			Escape: () => {
				if (searchBoxRef.current === document.activeElement) {
					setSearchBoxFocus(false);
				} else {
					handleBackArrow();
				}
			},
			Backspace: () => {
				if (searchBoxRef.current !== document.activeElement) {
					handleBackArrow();
				}
			}
		}
	);

	const handleSelectProvider = (index) => {
		for (const category of ORDERED_CATEGORIES) {
			const categorySize = grouped[category]?.length ?? 0;
			if (index >= categorySize) {
				index -= categorySize;
			} else {
				handleClick(grouped[category][index]);
				return;
			}
		}
	};


	const getGroup = (provider) => {
		return  provider.url.split('/')[0];
	};

	const filteredArray = providersData.filter(provider => {
		return provider.url.toUpperCase().includes(filter.toUpperCase()) || provider.description.toUpperCase().includes(filter.toUpperCase());
	});
	const grouped = filteredArray.reduce((r,a) =>{
		r[getGroup(a)] = r[getGroup(a)] || [];
		r[getGroup(a)].push(a);
		return r;
	}, Object.create(null));

	const { selectedIndex } = useKeyboardNavigation(handleSelectProvider, filteredArray);

	const handleClick = (provider) => {
		setProvider(provider);
		onClick();
	};

	const getIndexBaseByCategory = (category) => {
		const baseIndexByCategory = {};
		ORDERED_CATEGORIES.reduce((acc, c) => {
			baseIndexByCategory[c] = acc;
			return acc + (grouped[c]?.length ?? 0);
		}, 0);
		return baseIndexByCategory[category];
	};

	return (
		<>
			<ModalHeader
				handleBackArrow={handleBackArrow}
				handleClose={handleClose}
				providerLogo={BlockmateLogoBlack}
				withArrow
			/>
			<div className='select-provider-container'>
				<div className='providers-list-container'>
					<div className='select-provider'>
						<p className='select-provider-text'>Select your provider</p>
					</div>
					{
						ORDERED_CATEGORIES
							.filter(category => grouped[category] !== undefined && grouped[category].length > 0)
							.map(category => {
								return <SearchCategory
									key={category}
									title={category.toUpperCase()}
									items={grouped[category]}
									selectedIndex={(selectedIndex ?? -1) - getIndexBaseByCategory(category)}
									handleClick={handleClick}
								/>;
							})
					}
					<div className='wallet-connect-button-container'>
						<MainButton
							label='Wallet Connect'
							icon={<WalletConnectIcon className='wallet-connect-icon' />}
							onClick={onButtonClick}
							className='confirm-button'
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default SearchProviderAmlDemo;
