import './savingSuccess.scss';
import MainButton from '../../button/MainButton';
import {ReactComponent as BlockmateGreen} from '../../../assets/svg/blockmate-symbol-green.svg';
import {ReactComponent as BoltGreen} from '../../../assets/svg/bolt-green.svg';

const SavingSuccess = ({onClick}) => {
	return (
		<div className='modal-content saving-success'>
			<div className='success-content'>
				<div className='success-logo'>
					<BlockmateGreen/>
				</div>
				<div className='success-text'>
					<div className='success-title'>
						<p>Opening of your account was successful!</p>
					</div>
					<p className='success-description'>
						Please store your Ownership certificate in a safe place! It ensures only you have a full control of your digital assets account.
					</p>
				</div>
			</div>
			<MainButton
				className='confirm-button'
				onClick={onClick}
				label='Continue'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
		</div>
	);
};

export default SavingSuccess;