import './enable-transfer-modal.scss';
import {useEffect, useState} from 'react';
import { useSearchParams} from 'react-router-dom';
import ModalHeader from '../modal-components/modal-header/ModalHeader';
import ModalFooter from '../modal-components/modal-footer/ModalFooter';
import EnableTransferWallet from './EnableTransferWallet';
import EnableTransferConfirm from './EnableTransferConfirm';
import useApiInstances from '../../services/api-call/axios';
import ErrorValidation from '../errors/ErrorValidation';
import useStepNavigation from '../../hooks/useStepNavigation';
import LoadingScreen from '../status/LoadingScreen';
import {handleClose} from 'blockmate-react-link';

export const STEPS = {
	SET_WALLET: 1,
	CONFIRM: 2,
};

const EnableTransferModal = () => {
	const [apiKey, setApiKey] = useState(null);
	const [apiSecret, setApiSecret] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [account, setAccount] = useState(null);
	const [provider, setProvider] = useState(null);
	const {step, handleBackArrow, handleNextStep} = useStepNavigation(STEPS.SET_WALLET);
	const [searchParams] = useSearchParams();
	const {apiLink} = useApiInstances();

	const providedAccount = searchParams.get('accountId');

	useEffect(  () => {
		const getAccounts = async () => {
			try {
				const response = await apiLink.get('accounts');
				if (response.status === 200) {
					setAccount(response.data.find(account => account.id === providedAccount));
				}
			} catch (error) {
				setIsLoading(false);
			}
		};

		getAccounts();
	}, []);

	useEffect(  () => {
		const getProviderDetails = async () => {
			const cryptoSourceData = account.url.split('/')[0];
			try {
				const response = await apiLink.get(`${cryptoSourceData}_${account.name}`);
				setProvider(response.data);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};

		getProviderDetails();
	}, [account !== null]);

	if (isLoading) return <LoadingScreen/>;

	if (!provider && !isLoading) return (
		<div className='modal-container'>
			<div className='modal-content'>
				<ErrorValidation onClick={() => handleClose()} withExitIcon/>
			</div>
		</div>
	);

	return (
		<div className='modal-container'>
			<div className='modal-content phone-modal'>
				{provider && (
					<ModalHeader
						handleBackArrow={handleBackArrow}
						handleClose={() => handleClose()}
						providerLogo={provider?.icon}
						withArrow={step === STEPS.CONFIRM}
					>
						{provider?.description}
					</ModalHeader>
				)}
				{step === STEPS.SET_WALLET && (
					<EnableTransferWallet
						apiKey={apiKey}
						setApiKey={setApiKey}
						setApiSecret={setApiSecret}
						apiSecret={apiSecret}
						handleNextStep={handleNextStep}
						providerIp={provider?.payment?.ip_addresses}
					/>
				)}
				{step === STEPS.CONFIRM && (
					<EnableTransferConfirm
						apiKey={apiKey}
						apiSecret={apiSecret}
						accountId={providedAccount}
						provider={provider}
					/>
				)}
				<ModalFooter/>
			</div>
		</div>
	);
};

export default EnableTransferModal;
