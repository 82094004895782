import './change-phone.scss';
import Input from '../input/Input';
import MainButton from '../button/MainButton';
import clsx from 'clsx';
import {validatePhoneForE164} from '../../utils/phoneValidation';
import {ReactComponent as BoltGreen} from '../../assets/svg/bolt-green.svg';
import {ReactComponent as BlockmateInfo} from '../../assets/svg/blockmate-info-yellow.svg';

const SetPhone = ({invalidData, setInvalidData, setPhone, phone, initializePhone}) => {
	const handleChange = (e) => {
		setInvalidData(false);
		setPhone(e.target.value);
		validatePhoneForE164(e.target.value);
	};

	const handlePhoneNumber = async () => {
		if (validatePhoneForE164(phone)) {
			setInvalidData(false);
			await initializePhone();
		} else {
			setInvalidData(true);
		}
	};

	return (
		<div className='content'>
			<div className='logo-info'>
				<BlockmateInfo/>
			</div>
			<div className='title'>
					Change verification phone number
			</div>
			<p className='description'>
					Proceed with caution, you`&apos;re about to change your verification phone number for secure asset
					transfers.
			</p>
			<div>
				<Input
					onChange={ handleChange }
					type='tel'
					id='phone'
					className={clsx(
						'provider-input',
						invalidData && 'input-error',
					)}
					placeholder='New phone number'
				/>
				<div className='error-text'>
					{ invalidData && 'Wrong phone format' }
				</div>
			</div>
			<MainButton
				className='confirm-button'
				onClick={ handlePhoneNumber }
				disabled={ !phone }
				label='Continue'
				icon={<BoltGreen className='bolt-icon'/>}
			/>
		</div>
	);
};

export default SetPhone;
