export default async function encryptData(publicKey, plainText) {
	const encodedText = new TextEncoder().encode(plainText);
	const encryptedBuffer = await crypto.subtle.encrypt(
		{
			name: 'RSA-OAEP'
		},
		publicKey,
		encodedText
	);

	const encryptedArray = new Uint8Array(encryptedBuffer);
	const encryptedBytes = Array.from(encryptedArray);
	return btoa(String.fromCharCode(...encryptedBytes));
}