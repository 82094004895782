import styles from './exchange-sign-in.module.scss';
import Input from '../../../input/Input';
import {useState} from 'react';
import MainButton from '../../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';
import {ReactComponent as ShieldWithKey} from '../../../../assets/svg/shield-with-key.svg';

const ExchangeSignIn = ({ provider, handleNextStep, canProceed = true }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	return <div className={styles.container}>
		<div></div>{/* Top marker for even spacing, do not remove */}

		<div className={styles.mainContent}>
			<div className={styles.heading}>
				<div className={styles.title}>
					Sign in
				</div>
				<div className={styles.explanation}>
					Enter your {provider?.description} login credentials
				</div>
			</div>
			<div className={styles.formInput}>
				<div className={styles.label}>
					{provider?.description} email
				</div>
				<Input
					onChange={handleEmailChange}
					type='text'
					placeholder='Your email'
				/>
			</div>
			<div className={styles.formInput}>
				<div className={styles.label}>
					{provider?.description} password
				</div>
				<Input
					onChange={handlePasswordChange}
					type='password'
					placeholder='Your password'
				/>
			</div>
			<MainButton
				className='confirm-button'
				onClick={handleNextStep}
				label='Continue'
				icon={<BoltGreen className='bolt-icon'/>}
				disabled={!canProceed}
			/>
		</div>

		<div className={styles.footer}>
			<div className={styles.iconContainer}>
				<ShieldWithKey />
			</div>
			<div className={styles.text}>
				Your data is private and encrypted at all times
			</div>
		</div>
	</div>;
};

export default ExchangeSignIn;
