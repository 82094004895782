import styles from './direct-deposit-done.module.scss';
import Success from '../../../status/Success';
import MainButton from '../../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';

const DirectDepositDone = ({ handleClose }) => {
	return <div className={styles.container}>
		<div className={styles.topPart}>
			<Success />
			<div className={styles.title}>
				Submitted successfully
			</div>
			<div className={styles.description}>
				Your deposit will be credited to your account once the transaction has enough
				confirmations on the blockchain.
			</div>
		</div>

		<div className={styles.bottomPart}>
			<MainButton
				className='confirm-button'
				onClick={handleClose}
				icon={<BoltGreen className='bolt-icon'/>}
				label='Close window'
			/>
		</div>
	</div>;
};

export default DirectDepositDone;
